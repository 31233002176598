<template>
  <div id="app">
    <transition name="slide-fade" mode="out-in">
      <router-view v-if="shows" />
      <div v-else class="jzzs">
        <div>
          <van-loading type="spinner" color="#000" />
        </div>
        <div>{{ tet }}</div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  provide() {
    //父组件中通过provide来提供变量，在子组件中通过inject来注入变量。
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      shows: true,
      tet: "钱包连接中...",
      screenWidth: document.body.clientWidth,
    };
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth;
        this.screenWidth = window.screenWidth;
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.show = this.screenWidth > 800 ? false : true;
        this.$store.commit("phone", this.show);
      })();
    };
  },

  created() {
    this.screenWidth = document.body.clientWidth;
    this.show = this.screenWidth > 800 ? false : true;
    this.$store.commit("phone", this.show);
  },
  methods: {
    reload() {
      this.isRouterAlive = false; //先关闭，
      this.$nextTick(function () {
        this.isRouterAlive = true; //再打开
      });
    },
  },
};
</script>

<style lang="scss">
::-webkit-scrollbar {
  width: 0 !important;
}
.van-toast {
  font-size: 36px !important;
  line-height: 36px !important;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #ffffff;
}
html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.el-popper{
  z-index: 9999 !important;
}
a {
  color: inherit;
  text-decoration: none;
}

*,
:after,
:before {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

.spinner {
  -webkit-animation: rotate 2s linear infinite;
  -moz-animation: rotate 2s linear infinite;
  animation: rotate 2s linear infinite;
  margin: -25px 0 0 -25px;
  width: 50px;
  height: 50px;
}

.spinner .path {
  stroke: #fff;
  stroke-linecap: round;
  -webkit-animation: dash 1.5s ease-in-out infinite;
  -moz-animation: dash 1.5s ease-in-out infinite;
  animation: dash 1.5s ease-in-out infinite;
}

@-webkit-keyframes rotate {
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@-moz-keyframes rotate {
  to {
    -moz-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@keyframes rotate {
  to {
    -webkit-transform: rotate(1turn);
    -moz-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }

  to {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

@-moz-keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }

  to {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }

  to {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
@font-face {
  font-family: HAPEBEAST_Extended;
  src: url("~@/assets/font/HAPEBEAST_BODL.b52fe42b.TTF");
}
@font-face {
  font-family: fBold;
  src: url("~@/assets/font/file.woff2");
}

@font-face {
  font-family: S5ae;
  src: url("~@/assets/font/5ae.woff2");
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #ae9761;
    }
  }
}
.van-popup{
  background-color: transparent !important;
}
.van-popup__close-icon {
  top: 21px  !important;
  right: 32px  !important;
  font-size: 24px !important;
  color: #333333 !important;
}
.jzzs {
  height: 100vh;
}
.jzzs div {
  padding-top: 20%;
  font-size: 23px;
  color: #2c3e50;
}
.van-notify {
  font-size: 26px !important;
  line-height: 50px !important;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fadeIn {
  animation-duration: 0.8s;
  animation-name: fadeIn;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 40px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
.fadeInUp {
  animation-duration: 0.8s;
  animation-name: fadeInUp;
}

@keyframes fadeInDow {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
.fadeInDow {
  animation-duration: 0.8s;
  animation-name: fadeInDow;
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(40px, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
.fadeInRight {
  animation-duration: 0.8s;
  animation-name: fadeInRight;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-40px, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
.fadeInLeft {
  animation-duration: 0.8s;
  animation-name: fadeInLeft;
}
.van-pagination__item{
      background-color: transparent !important;
}
.van-pagination__item--active{
  background-color:#1989fa!important
}
.text-xl.svelte-1mtuslq.svelte-1mtuslq{
  font-size:30px !important;
  line-height: 60px !important;
}
.p-3.svelte-1mtuslq.svelte-1mtuslq{
  padding:22px !important;
  font-size: 22px !important;
}
.p-4.svelte-1mtuslq.svelte-1mtuslq{
  padding: 22px !important;
}
.w-8.svelte-1mtuslq.svelte-1mtuslq{
  width: 1rem !important;
}
.h-8.svelte-1mtuslq.svelte-1mtuslq{
  height:0.5rem !important;
}
.mb-4.svelte-1mtuslq.svelte-1mtuslq{
  margin-bottom: 10px !important;
}

</style>
