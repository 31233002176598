import Vue from 'vue'
import 'lib-flexible'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant';
import 'vant/lib/index.css';
import VueI18n from "vue-i18n";
import axios from 'axios';
import Qs from 'qs'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import VueClipboard from 'vue-clipboard2'
import './plugins/rem'
import md5 from 'js-md5';
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: store.getters.language, // 语言标识
  //this.$i18n.locale // 通过切换locale的值来实现语言切换
  messages: {
    'zh-CN': require('./lang/zh'), // 中文语言包
    'en-US': require('./lang/en') // 英文语言包
  }
})
Vue.use(ElementUI)
VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)
Vue.use(Vant);

Vue.config.productionTip = false
Vue.prototype.$axios = axios;
Vue.prototype.qs = Qs;
Vue.prototype.$get = get;
Vue.prototype.$post = post;
Vue.prototype.$md5 = md5;
// axios.AddHeader("Access-Control-Allow-Origin", "*")
axios.defaults.baseURL = '/api/'
axios.defaults.headers["content-type"] = "application/json";
axios.defaults.headers["Access-Control-Allow-Origin"] = "*";
axios.defaults.headers["Access-Control-Allow-Headers"] = "X-Requested-With,Content-Type";
axios.defaults.headers["Access-Control-Allow-Methods"] = "GET";
// let development=process.env.NODE_ENV == 'development' ? true : false;
// var root = "";

// function url(a) {
//   var url = root + a;
//   return url
// }
NProgress.configure({
  easing: 'ease', // 动画方式    
  speed: 500, // 递增进度条的速度    
  showSpinner: false, // 是否显示加载ico    
  trickleSpeed: 200, // 自动递增间隔    
  minimum: 0.3 // 初始化时的最小百分比
})

router.beforeEach((to, from, next) => {
  // 每次切换页面时，调用进度条
  NProgress.start();
  next();
});



router.afterEach(() => {
  // 在即将进入新的页面组件前，关闭掉进度条
  NProgress.done()
})
let domain = 'https://www.hnziqin.com/';
function dataSign(params, domain, url){
  let sign = ''
  let newData = JSON.parse(JSON.stringify(params));
  newData.key = 'MDAN85627G2857B77B7D0CE0A989549INGY9D2FE' //口令
  newData.domain = domain//域名
  newData.pathinfo = url//接口地址
  newData.timestamp = new Date().getTime()//当前时间戳
  sign = objKeySort(newData)
  return { sign: md5(sign), timestamp: newData.timestamp }
}

function objKeySort(obj) {//排序的函数
  var newkey = Object.keys(obj).sort();
  var newObj = '';
  for (var i = 0; i < newkey.length; i++) {
    // newObj[newkey[i]] = obj[newkey[i]];
    newObj = newObj + `&${[newkey[i]]}=${obj[newkey[i]]}`;
  }
  return newObj.substring(1);
}

function get(obj) {
  let token = store.getters.token || ''
  let postData = obj.data;
  let api = domain + obj.url;
  let sign = dataSign(postData, domain, obj.url)
  let header = {
    "content-type": "application/json;charset=UTF-8",
    "timestamp": sign.timestamp,
    "token": token,
    "sign": sign.sign,
  }
  return new Promise((resolve, reject) => {
    axios.get(api, {
      params: postData,
      headers: header,
    }).then(res => {
      if (res.data) {
        obj.callback(res.data);
      }
    }).catch(err => {
      reject(err)
    });
  })
}

function post(obj) {
  let token = store.getters.token || ''
  let postData = obj.data;
  let api = domain+obj.url;
  let sign = dataSign(postData, domain, obj.url)
 let header = {
  "content-type": "application/json;charset=UTF-8",
  "timestamp": sign.timestamp,
  "token": token,
  "sign": sign.sign,
}
  return new Promise((resolve, reject) => {
    axios.post(api, postData, {
      headers: header,
    }).then(res => {
      if (res.data) {
        obj.callback(res.data);
      }
    }).catch(err => {
      reject(err)
    });
  })
}
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')