<template>
  <van-popup
    v-model="shows"
    round
    @close="close"
     :position="isH5?'bottom':'center'"
    :close-on-click-overlay="false"
    :closeable="!loading"
  >
    <div class="popup" :class="isH5?'h5':''">
      <div class="stop">
      <div class="title">搜索</div>
      </div>
      <div class="box">
        <div class="item">
          <div class="laber">关键词：</div>
          <div class="right">
             <input type="text" v-model="keyword" class="input" placeholder="请输入关键词">
          </div>
        </div>

              <div class="item">
          <div class="laber">工作日期：</div>
          <div class="sright">

        <el-date-picker
      v-model="Datetime"
      type="daterange"
      align="right"
      class="time"
      z-index="9999999999"
      unlink-panels
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
      value-format="yyyy-MM-dd"
      :picker-options="pickerOptions2">
    </el-date-picker>

          </div>
        </div>
      </div>
 
      <div class="smbottom" >
         <van-button
          :loading="loading"
          type="primary"
          @click="close"
          class="btn qbtn"
          loading-text="Loading..."
        >
          取消</van-button
        >
        <van-button
          :loading="loading"
          type="primary"
          @click="Upadata"
          class="btn"
          loading-text="Loading..."
        >
          搜索</van-button
        >
      </div>
    </div>
  </van-popup>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      isH5:false,
      loading: false,
      myFht: 0,
      myTrx: 0,
      shows: false,
      free: 0,
      value: 75,
      active: 1,
      sqnum: 0,
      reshash:'',
      resID: {
        hase:''
      },
      task: "",
      hase: '',
      issq: false,
      currentDate:new Date(),
      formTime: '',
      toTime:'',
      tshow: false,
      tshows: false,
        detail: '',
        Datetime: [],
        keyword: "",
       pickerOptions2: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
      
    };
  },
  watch: {
    show(val) {
      this.shows = val;
      if (val) {
        this.detail = ''
         this.isH5 = this.$store.getters.phone;
      }
    },
  },
  methods: {
    confirm(e) {
    
        var dd = new Date(e);
              var y = dd.getFullYear();
      var m = dd.getMonth() + 1;//获取当前月份的日期 
      var d = dd.getDate();
             if (d < 10) {
                d = '0' + d;
      }
      console.log(y, m);
      this.data.start_time = y + '-' + m + '-' + d
      this.tshow=false
     },
    confirms(e) {
         var dd = new Date(e);
              var y = dd.getFullYear();
      var m = dd.getMonth() + 1;//获取当前月份的日期 
      var d = dd.getDate();
             if (d < 10) {
                d = '0' + d;
      }
      console.log(y, m);
      this.data.end_time = y + '-' + m + '-' + d
      this.tshows=false
     },
    close() {
      this.$emit("tshow", false);
    },
    toclose() {
      this.active=1
      this.$emit("tshow", false);
      this.$emit("updata", true);
    },
    
    Upadata() {
        let data = {
            keyword: this.keyword,
            start_time:this.Datetime.length==1? this.Datetime[0]:"",
            end_time:this.Datetime.length>1? this.Datetime[1]:""
        }
         this.$emit("updata", data);  
      
    },    
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../src/assets/scss/_handle.scss";
$t-mf: "PingFang";
$t-f: "DINAlternate-Bold";
.popup {
  width: 686px;
min-height: 445px;
background: #FFFFFF;
border-radius: 4px;

  .stop {
    height: 69px;
   border-bottom: 1px solid #DCDEE0;
     .title {
    text-align: left;
    margin-bottom: 32px;
    font-size: 19px;
font-weight: 500;
color: #323233;
line-height: 69px;
margin-left: 32px;
  }
  }
 
  .box{
    padding: 29px 0 19px;
    .item{
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 24px ;
      .laber{
font-size: 19px;
font-weight: 400;
color: #323233;
line-height: 27px;
      }
      .right{
        width: 480px;
        background: #FFFFFF;

        .sinput{
            padding: 0 20px;
          width: 100%;
          height: 50px;
          font-size: 19px;
          color: #030304;
          border: 1px solid #DCDEE0;
          border-radius: 3px;
          text-align: left;
          line-height: 50px;
        }
        input{
          padding: 0 20px;
          width: 100%;
          height: 50px;
          font-size: 19px;
          color: #030304;
          border: 1px solid #DCDEE0;
          border-radius: 3px;
        }
        .txear{
          width: 100%;
          height: 100%;
            min-width: 480px;
          max-width: 480px;
          min-height: 200px;
         border: 1px solid #DCDEE0;
           font-size: 19px;
             padding: 20px;
             color: #030304;
             border-radius: 3px;
        }
      }
      .sright{
        display: flex;
        align-items: center;
        justify-content: space-between;
         width: 480px;
         height: 68px;
         .time{
           width: 100%;
           font-size: 26px;
         }
         .datetime{
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 200px;
          overflow: hidden;
position: relative;
border-radius: 3px;
border: 1px solid #DCDEE0 ;
height: 43px;
padding: 0 16px;
          .tsxt{
           font-size: 15px;
font-weight: 400;
color: #323233;
line-height: 27px;
white-space: nowrap;
          }
         }
         .datetime:hover{
          border: 1px solid #2163F8;
          box-shadow: 0px 0px 5px 0px rgba(10,42,97,0.2);
          cursor: pointer;
         }
         .popp{
          position: absolute;
          top: 0;
          width: 100%;
          background: #d7ddeb;
         }
         .txt{
          font-size: 19px;
font-weight: 400;
color: #323233;
line-height: 27px;
         }
      }
    }
  }

   .smbottom {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 22px;
      .btn {
    font-size: 19px;
    font-family: $t-mf;
    font-weight: bold;
    color: #ffffff;
    line-height: 46px;
    margin: 10px;
 min-width: 99px;
height: 43px;
background: #2163F8;
border-radius: 3px;
border: none;
  }
      .qbtn {
     background: #FFFFFF;
border-radius: 3px;
border: 1px solid #DCDEE0;
color: #030304;
    }
  }
  .ctne{
      justify-content: center;
      .btn{
        width: 200px;
        cursor: pointer;
      }
  }
}

.h5{
    width: 100%;
    .stop{
      height: 88px;
      .title{
        font-size: 28px;
      }
    }
    .box{
      .item{
        .laber{
          font-size: 22px;
          min-width: 160px;
        }
        .right{
          .el-select{
             height: 68px;
            line-height: 68px;
            }
                ::v-deep .el-input__inner{
                height: 68px;
                line-height: 68px;
              }
            
          input{
            height: 68px;
            line-height: 68px;
          }
          .sbtn{
            height: 88px;
            width: 160px;
            border-radius: 8px;
          }
          .txear{
            max-width: 100%;
          }
        }
      }
    }
      ::v-deep .el-input__inner{
                width: 100% !important;
                height: 68px !important;
                line-height: 68px !important;
              }
           ::v-deep    .el-range-separator{
                line-height: 68px !important;
              }
              .smbottom{
                .btn{
                  height: 68px;
                  width: 160px;
                  font-size: 28px;
                }
              }

  }
</style>
