<template>
  <van-popover v-model="show"  :actions="actions" @select="onSelect"> 
            <template #reference> 
            <img src="../assets/img/icon-jiantou-bai@2x.png"   @click="toshow" srcset="" class="img">
       </template>
 </van-popover>
</template>
<script>
export default {
    props: {     
        data: {
            type: String,
            default: () => {
                return "123456";
            },
        },
    },
    data() {
        return {
            actions: [{ text: '' }],
            show:false
        }
    },
watch: {
    // show(val) {
    //   this.show = val;
    //   if (val) {
    //     this.$set(this.actions[0],'text',this.data)
    //   }
    // },
    },

    methods: {
        onSelect(e) { 
            
        this.$post({
          url: "v1/LoginOut",
          data: {},
          callback: (res) => {
            this.$nextTick(function () {
              this.loading = false;
              if (res.code == 200) {             
                this.$store.commit("token", '');
                  this.$store.commit("userWallet", '');
                 this.$router.replace({ path: '/' })
              } else {
                this.$notify(res.msg);
              }
            });
          },
        });
               
        },
        toshow() { 
            this.$set(this.actions[0], 'text', this.data)
             this.show=true
        },
    }
} 

</script>
<style scoped lang="scss">

.img{
     width: 15px;
        height: 15px;
        margin-left:10px ;
            border-radius: 100%;
            margin: 0 20px;
}
</style>