<template>
  <div class="cards">
    <div class="stopw">
      <div class="title">日历</div>
      <div class="more" @click="toAdd()">添加备忘</div>
    </div>
    <div class="xq">
      <div class="ii">日</div>
      <div class="ii">一</div>
      <div class="ii">二</div>
      <div class="ii">三</div>
      <div class="ii">四</div>
      <div class="ii">五</div>
      <div class="ii">六</div>
    </div>
      <div class="day">
           <div class="cell"  v-for="(item,i) in days" 
         :key="i" @click="tocatday(item.date)" :class="item.isSign?'bw':''">
           <div class="ii" :class="actDay==item.date?'act':''" >
            {{ item.date==day?'今':item.date }}</div>
        </div>
    </div> 
    <div class="slist">
      <div class="items fadeInUp" v-for="(item,i) in list" :key="i">
        {{item.content}} ｜{{item.time_start||"00:00"}} ~ {{item.time_end ||"23:59" }}
      </div>
    </div>
        <beiwan  :show="addshow"
    :data="ftime"
      @tshow="
        (val) => {
          addshow = val;
        }"
          @updata="
        (val) => {
          getAll()
          getList()
        }
      "
    />
  </div>
</template>
<script>
import beiwan from '../components/beiwan.vue'
export default {
        components: {
        beiwan
    },
    data() { 
        return {
            actDay: "", 
            day: '',
            list: [],
          ftime: '',
            days:[],
          addshow: false,
              SignUp:[]
        }
    },
    created() { 
        this.day = parseInt(new Date().getDate())
        this.actDay = this.day
         this.toMonth = parseInt(new Date().getMonth() + 1); //本月
      this.toYear = parseInt(new Date().getFullYear()); //本年
      this.days = [
        { date: '',isSign:false},
        { date: '' ,isSign:false},
        { date: '' ,isSign:false},
        { date: '' ,isSign:false},
        { date: '' ,isSign:false},
        { date: '' ,isSign:false},
        { date: '' ,isSign:false}]
      this.jsdays()
      this.getList()
     
    },
  methods: {
    jsdays() { 
      let a = new Date(Date.UTC(this.toYear, this.toMonth - 1, this.day)).getDay();   
      for (let index = 0; index < 7; index++) {        
        this.$set(this.days[index],'date',this.day+(index-a))
      }
       this.getAll()
     
     
    },
         getAll() { 
            let l=this.days.length-1
             let  data={
                 start_time: this.toYear + '-' + this.toMonth + '-' + 1+" 00:00:00",
                 end_time :this.toYear +'-'+this.toMonth+'-'+this.days[l].date+" 23:59:59",                 
            }
            console.log(data);
                this.$post({
        url: "getCalendarSign",
             data: data,
             callback: (res) => {
          this.$nextTick(function () {
              this.SignUp = res.data
                 this.onJudgeSign();     
          });
        },
      });
    },
    onJudgeSign() {
				var signs = this.SignUp;
				var daysArr = this.days;
				for (var i = 0; i < signs.length; i++) {
					var current = new Date(signs[i].day.replace(/-/g, "/"));
					var year = current.getFullYear();
					var month = current.getMonth() + 1;
					var day = current.getDate();
					day = parseInt(day);
					for (var j = 0; j < daysArr.length; j++) {
						//年月日相同则打卡成功   						
						if (year == this.toYear && month == this.toMonth && daysArr[j].date == day) { //&& signs[i].isSign == "今日已打卡"
							// console.log(daysArr[j].date, day);
							daysArr[j].isSign = true;
						}
					}
				}
      this.days = daysArr;
        console.log(this.days);
			},
        
          toAdd() {       
            this.ftime = this.toYear + '-' + this.toMonth + '-' + this.actDay
            if (this.addshow) { this.addshow = false }
            setTimeout(() => {
                this.addshow = true
            }, 100);
         },
          getList(data) { 
            if (!data) {
        data={
                 start_time: this.toYear + '-' + this.toMonth + '-' + this.actDay+" 00:00:00",
                 end_time :this.toYear +'-'+this.toMonth+'-'+this.actDay+" 23:59:59",                 
             }
        }
        this.loading=true
         this.$post({
        url: "getCalendarSign",
             data: data,
             callback: (res) => {
            this.list=[]
          this.$nextTick(function () {
              this.loading = false
               this.list=res.data
            // for(var key in res.data ){
            //     console.log(key)
            //     console.log(res.data[key]);
            // this.list.push( {'time':key,text:res.data[key]})   
            // }
           
          });
        },
      });
        },

        tocatday(e) {
                this.actDay = e
      this.getList()
     },
    },
    }
</script>
<style lang="scss">

 .cards{
    width: 678px;
min-height: 226px;
height: 100%;
background: #FFFFFF;
border-radius: 16px;
margin: 20px auto;
transition: height 0.2s ease-in;

.stopw{
  display: flex;
  justify-content: space-between;
  padding: 24px;
  border-bottom: 1px solid  #EAF1F4;
  .title{
font-size: 28px;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 500;
color: #181818;
line-height: 40px;
  }
  .more{
    font-size: 24px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #9FA4BB;
line-height: 34px;
.ss{
  width: 26px;
  height: 26px;
}
  }
}
.xq{
  padding: 24px;
  font-size: 24px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #9FA4BB;
line-height: 34px;
display: flex;
justify-content: space-between;
align-items: center;
}
.day{
    padding: 24px;
  font-size: 28px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #181818;
line-height: 34px;
display: flex;
justify-content: space-between;
align-items: center;
}
.act{
  width: 60px;
height: 60px;
background: #445AF7;
border-radius: 100%;
color: #ffffff;

}
.cell{
         width: 60px;         
  height: 60px;
  display: flex;
align-items: center;
justify-content: center;
border-radius: 100%;
}
.ii{
  width: 60px;
  height: 60px;
    display: flex;
align-items: center;
justify-content: center;
}
  .bw{
              position: relative;
  width: 60px;
  height: 60px;
            }
            .bw::after{
                position: absolute;
                content: "";
                background: red;
                width: 5px;
                border-radius: 100%;
                height: 5px;
                bottom: 5px;
            }
.slist{
  display: flex;
  color: #000000;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;

  .items{
    position: relative;
    margin-left: 32px;
    padding-left: 20px;
    font-size: 24px;
font-weight: 400;
color: #181818;
line-height: 34px;
margin:10px 32px;
  }
  .items::before{
    position: absolute;
    left: -12px;    
    content: "";
    width: 12px;
    top: 12px;
height: 12px;
background: #F12A49;
transform: translate(-50% ,-50%);
border-radius: 100%;
  }
}


  }

</style>