<template>
  <van-popup
    v-model="shows"
    round
    @close="close"
      :position="isH5?'bottom':'center'"
    :close-on-click-overlay="false"
    :closeable="!loading"
  >
    <div class="popup" :class="isH5?'h5':''">
      <div class="stop">
      <div class="title" >{{active==1?'共享文档':info.name}}</div>
      </div>
      <div class="box" v-if="active==1">
        <div class="nsttop">    
          <div class="item"  v-for="(item,i) in list" :key="i" @click="toshow(item)">
            <img src="../assets/img/wjj.png" alt="" srcset="" class="ico">
          <div class="laber">{{item.name}}</div>
            <div class="time">{{item.end_time}}</div>
          </div>
        </div>
      </div>
      <div class="box" v-if="active==2">
      
      <div class="search">
        <input type="text" placeholder="名称" v-model="keyname"  @input="search">
        <van-uploader accept="." :after-read="upfiles">
          <van-button icon="plus" class="sbtn" type="primary"  >上传</van-button>
        </van-uploader>
        <!-- <div class="sbtn">添加</div> -->
      </div>
      <div class="list">
         <div class="items"  v-for="(file,is) in file_list" :key="is">
              <div class="stxt">
                <img src="../assets/img/icon-wjsc.png" alt="" srcset="" class="ico">
                {{ file.file_name }}</div>
              <div class="tunm">
                <span @click="tosee(file.file_path)">查看</span>
                <span @click="downloadFile(file)">导出</span>
              </div>
          </div>
      </div>
      
      </div>
      <div class="smbottom">
         <van-button
          :loading="loading"
          type="primary"
          @click="iclose"
          class="btn qbtn"
        >
          {{active==1?"关闭":"返回"  }}</van-button
        >      
      </div>
    </div>
  </van-popup>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      isH5:false,
      loading: false,
      myFht: 0,
      myTrx: 0,
      shows: false,
      free: 0,
      value: 75,
      active: 1,
      sqnum: 0,
      reshash:'',
      resID: {
        hase:''
      },
      keyname:"",
      task: "",
      hase: '',
      issq: false,
      currentDate:new Date(),
      formTime: '',
      toTime:'',
      tshow: false,
      tshows: false,
      detail: '',
      startTime: '2023-06-28',
      endTime: "",
      list: [],
      info: '',
      file_list: [],
      file:[]
      
    };
  },
  watch: {
    show(val) {
      this.shows = val;
      if (val) {
        this.getlist()
          this.isH5 = this.$store.getters.phone;
      }
    },
  },
    computed:{
    ftime() {
         var dd = new Date();
            var y = dd.getFullYear();
      var m = dd.getMonth() + 1;//获取当前月份的日期 
      var d = dd.getDate();
             if (d < 10) {
                d = '0' + d;
      }
      return y+ '-' + m + '-' + d
     },
 },
  methods: {
    upfiles(file) { 
      console.log(file);
      let form = new FormData()
      form.append('file', file.file)
        this.$post({
          url: "/upFile",
          data: form,
          callback: (res) => {
            this.$nextTick(function () {
              this.loading = false;              
                console.log(res);
              if (res.code == 200) {
                this.file = { 'file_name': file.file.name, 'file_path': res.msg }
                this.file_list.unshift(this.file)
                this.updatas()
                 console.log(this.file);
              } else {
                this.$notify(res.msg);
              }
            });
          },
        });
    },
    updatas() {
         this.$post({
          url: "/saveShareFile",
          data: {
            id: this.info.id,
            file_list:[this.file]
          },
          callback: (res) => {
            this.$nextTick(function () {
              this.loading = false;
              if (res.code == 200) {
                
              } else {
                this.$notify(res.msg);
              }
            });
          },
        });
     },
    search() { 
      if (this.keyname) {
        let base=this.info.file_list
        this.file_list = this.getArrayByName(this.keyname,base , base.length)
      } else {
        this.file_list=this.info.file_list
      }
    },
    getArrayByName(name, array, length) {
      if (array.length < 1) {
        return   ;
      }
      var result = [];
      for (var key in array) {
        if (this.checkStrContain(array[key].file_name, name) && result.length < length) {
          result.push(array[key])
        }
      }
      return result
    },

    checkStrContain(i, j) {
        if(!i || !j){
            return false;
        }
        if(i.charAt(0) != j.charAt(0)){
            return false;
        }
        i = i.substr(1,i.length-1);
        j = j.substr(1,j.length-1);
        var a;
        var b;
        if (i.length > j.length) {
            a = i;
            b = j;
        } else {
            a = j;
            b = i;
        }
        var count = 0;
        for (var bi = 0; bi < b.length; bi++) {
            var bArr = b.split("");
            if (a.indexOf(bArr[bi]) != -1) {
                count++;
            } else {
                break;
            }
        }
        if (b.length == count) {
            return true;
        } else {
            return false;
        }
    },
    toshow(e) {
      this.active = 2
      this.info = e
      this.file_list=e.file_list
     },
    tosee(url) { 
      window.open(url)
    },
    downloadFile(file) { 
      let a = document.createElement("a");
    a.setAttribute("href", file.file_path );
    a.setAttribute("download", file.file_name);
    a.setAttribute("target", "_blank");
    let clickEvent = document.createEvent("MouseEvents");
    clickEvent.initEvent("click", true, true);
    a.dispatchEvent(clickEvent);
    },
    getlist() {       
            this.$post({
          url: "/getShareFile",
          data: {
            start_time: this.startTime,
            end_time:this.endTime
          },
          callback: (res) => {
            this.$nextTick(function () {
              this.loading = false;
              if (res.code == 200) {
                 this.list=res.data
              } else {
                this.$notify(res.msg);
              }
            });
          },
        });
    },
    confirm(e) {
    
        var dd = new Date(e);
              var y = dd.getFullYear();
      var m = dd.getMonth() + 1;//获取当前月份的日期 
      var d = dd.getDate();
             if (d < 10) {
                d = '0' + d;
      }
      console.log(y, m);
      this.startTime = y + '-' + m + '-' + d
      this.tshow = false
      this.getlist()
     },
    confirms(e) {
         var dd = new Date(e);
              var y = dd.getFullYear();
      var m = dd.getMonth() + 1;//获取当前月份的日期 
      var d = dd.getDate();
             if (d < 10) {
                d = '0' + d;
      }
      console.log(y, m);
      this.endTime = y + '-' + m + '-' + d
      this.tshows = false
        this.getlist()
     },
    close() {
      this.$emit("tshow", false);
    },

    iclose() { 
      if (this.active != 1) {
        this.active=1
      } else {
        this.$emit("tshow", false);
      }
    },
    toclose() {
      this.active=1
      this.$emit("tshow", false);
      this.$emit("updata", true);
    },
    
    Upadata() {     
      if (!this.detail) { return }
         this.loading = true
        this.$post({
          url: "/apply",
          data: {
            reason : this.detail,
          },
          callback: (res) => {
            this.$nextTick(function () {
              this.loading = false;
              if (res.code == 200) {
                 this.detail=''
                this.$emit("tshow", false);
                this.$emit("updata", true);
              } else {
                this.$notify(res.msg);
              }
            });
          },
        });
      
        

    },    
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../src/assets/scss/_handle.scss";
$t-mf: "PingFang";
$t-f: "DINAlternate-Bold";
.popup {
  width: 686px;
min-height: 445px;
background: #FFFFFF;
border-radius: 4px;

  .stop {
    height: 69px;
   border-bottom: 1px solid #DCDEE0;
     .title {
    text-align: left;
    margin-bottom: 32px;
    font-size: 19px;
font-weight: 500;
color: #323233;
line-height: 69px;
margin-left: 32px;
cursor: pointer;
  }
  }
 
  .box{
    padding: 9px 0 19px;
    min-height: 550px;
    max-height: 550px;
    overflow: auto;
    .nsttop{
      border-bottom: 1px solid #f8f8f8;
      display: flex;
      flex-wrap: wrap;
      .item{
        display: flex;
        align-items: center;
      justify-content: center;
      flex-direction: column;
      margin: 10px;
      max-width: 150px;
      cursor: pointer;
      .ico{
      height:44px ;
      width: 44px;
      }     .laber{
font-size: 16px;
font-weight: 400;
color: #323233;
line-height: 27px;
width: 140px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
      }
      .time{
        font-size: 12px;
font-weight: 400;
color: #323233;
width: 140px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
      }
      }
      .item:hover{
        .laber{
          text-overflow: clip;
          text-shadow: 1px 2px 5px #f8f8f8;
          font-weight: 600;
        }
      }
    }
    .search{
          padding: 0 20px;
          width: 100%;
          height: 50px;
          font-size: 19px;
          color: #030304;
          border-radius: 3px;
          text-align: left;
          line-height: 50px;
          display: flex;
          align-items: center;
           input{
          padding: 0 20px;
          flex:1;
          height: 50px;
          font-size: 19px;
          color: #030304;
          border: 1px solid #DCDEE0;
          border-radius: 3px;
        }
        .sbtn{
          width: 88px;
           font-size: 14px;
    font-family: $t-mf;
    font-weight: bold;
    color: #ffffff;
    line-height: 46px;
    margin: 10px;
 min-width: 99px;
height: 43px;
background: #2163F8;
border-radius: 3px;
border: none;
text-align:center;
cursor: pointer;
        }
    }
    .list{
    .items{
  display: flex;
          justify-content: space-between;          
          align-items: center;
          margin: 10px 20px;
              border: 1px solid #f8f8f8;
              padding: 10px;
          .stxt{
            font-size: 19px;
            font-weight: 400;
            color: #323233;
            line-height: 20px;
            display: flex;
            align-items: center;
            .ico{
              width: 16px;
              height: 16px;
              margin-right: 12px;
            }
          }
          .tunm{
            span{
              font-size: 19px;
              font-weight: 400;
              color: #2163F8;
              line-height: 27px;
              margin-left: 32px;
              cursor: pointer;
            }
          }
    

    }
    .items:hover{
      background: #f5faff;
      cursor: pointer;
    }
  }
  }

   .smbottom {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 22px;
      .btn {
    font-size: 19px;
    font-family: $t-mf;
    font-weight: bold;
    color: #ffffff;
    line-height: 46px;
    margin: 10px;
 min-width: 99px;
height: 43px;
background: #2163F8;
border-radius: 3px;
border: none;
  }
      .qbtn {
     background: #FFFFFF;
border-radius: 3px;
border: 1px solid #DCDEE0;
color: #030304;
    }
  }
}
.h5{
    width: 100%;
    .stop{
      height: 88px;
      .title{
        font-size: 28px;
      }
    }
    .box{
      .nsttop{
        .item{
          max-width: 25%;
          .laber{
            font-size: 26px;
            width:100% ;
            margin: 10px 0;
          }
          .time{
            width: 100%;
            font-size: 20px;
          }
        }
      }
      .search{
        height: 68px;
        margin: 20px 0;
        input{
          height: 68px;
          line-height: 68px;
        }
        .sbtn{
          width: 160px;
          height: 68px;
          font-size: 26px;
        }
      }
      .list{
        .items{
            .stxt{
              font-size: 26px;
              line-height: 40px;
              .icon{
                width: 22px;
                height: 22px;
              }
            }
            .tnum{
              font-size: 26px;
            }
        }
      }
    }
      ::v-deep .el-date-editor.el-input{
                width: 100% !important;
              }
              .smbottom{
                .btn{
                  height: 68px;
                  width: 160px;
                  font-size: 28px;
                }
              }

  }
</style>
