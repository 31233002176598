<template>
  <van-popup
    v-model="shows"
    round
    @close="close"
    :position="isH5?'bottom':'center'"
    :close-on-click-overlay="false"
    :closeable="!loading"
  >
   <div class="popup" :class="isH5?'h5':''">
      <div class="stop">
      <div class="title">添加备忘</div>
      </div>
      <div class="box">
        <div class="item">
          <div class="laber">工作日期：</div>
          <div class="right">
            <input type="text"  readonly v-model="data">
          </div>
        </div>  
         <div class="item">
          <div class="laber">开始时间：</div>
          <div class="right">
            <el-time-select
  v-model="time_start"
  :picker-options="{
    start: '0:00',
    step: '00:15',
    end: '24:00'
  }"
  placeholder="选择时间">
</el-time-select>
          </div>
        </div>   
         <div class="item">
          <div class="laber">结束时间：</div>
          <div class="right">
            <el-time-select
  v-model="time_end"
  :picker-options="{
    start: time_start,
    step: '00:15',
    end: '24:00'
  }"
  placeholder="选择时间">
</el-time-select>
          </div>
        </div>    
           
              <div class="item">
          <div class="laber">内容：</div>
          <div class="right">
            <textarea name="" id="" cols="30" rows="10" v-model="detail" placeholder="请填写备忘内容" class="txear"></textarea>
          </div>
        </div>
  
      </div>
      <div class="smbottom">
         <van-button
          :loading="loading"
          type="primary"
          @click="close"
          class="btn qbtn"
        >
          取消</van-button
        >
        <van-button
          :loading="loading"
          type="primary"
          @click="Upadata"
          class="btn"
          loading-text="Loading..."
        >
          提交</van-button
        >
      </div>
    </div>
  </van-popup>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    data: {
      type: String,
      default: () => {
        return '';
      },
    },
  },
  data() {
    return {
        isH5:false,
      loading: false,
      myFht: 0,
      myTrx: 0,
      shows: false,
      free: 0,
      value: 75,
      active: 1,
      sqnum: 0,
      reshash:'',
      resID: {
        hase:''
      },
      task: "",
      hase: '',
      issq: false,
      currentDate:new Date(),
      formTime: '',
      toTime:'',
      tshow: false,
      tshows: false,
      detail: '',
      file_list: [],
      time_start: '',
      time_end:""
      
    };
  },
  watch: {
    show(val) {
      this.shows = val;
      if (val) {
        this.detail = ''
        this.file_list = []
         this.isH5 = this.$store.getters.phone;
      }
    },
  },
  computed:{
    ftime() {
         var dd = new Date();
            var y = dd.getFullYear();
      var m = dd.getMonth() + 1;//获取当前月份的日期 
      var d = dd.getDate();
             if (d < 10) {
                d = '0' + d;
      }
      return y+ '-' + m + '-' + d
     },
 },
  methods: {
    confirm(e) {
    
        var dd = new Date(e);
              var y = dd.getFullYear();
      var m = dd.getMonth() + 1;//获取当前月份的日期 
      var d = dd.getDate();
             if (d < 10) {
                d = '0' + d;
      }
      console.log(y, m);
      this.toTime = y + '-' + m + '-' + d
      this.tshow=false
     },
    confirms(e) {
         var dd = new Date(e);
              var y = dd.getFullYear();
      var m = dd.getMonth() + 1;//获取当前月份的日期 
      var d = dd.getDate();
             if (d < 10) {
                d = '0' + d;
      }
      console.log(y, m);
      this.formTime = y + '-' + m + '-' + d
      this.tshows=false
    },
      upfiles(file) { 
      let form = new FormData()
      form.append('file', file.file)
        this.$post({
          url: "/upFile",
          data: form,
          callback: (res) => {
            this.$nextTick(function () {
              this.loading = false;              
                console.log(res);
              if (res.code == 200) {
               let files = { 'file_name': file.file.name, 'file_path': res.msg }
                 this.file_list.unshift(files)
              } else {
                this.$notify(res.msg);
              }
            });
          },
        });
    },
    close() {
      this.$emit("tshow", false);
    },
    toclose() {
      this.active=1
      this.$emit("tshow", false);
      this.$emit("updata", true);
    },
    
    Upadata() {
    
      if (!this.detail) {return }
       this.loading=true
      this.$post({
        url: "/saveCalendarSign",
        data: {
          content: this.detail,  
          day: this.data ,
          time_start: this.time_start,
         time_end: this.time_end
         
        },
        callback: (res) => {
          this.$nextTick(function () {
            this.loading = false;
            if (res.code == 200) {
              //  this.active=1
              this.$emit("tshow", false);
              this.$emit("updata", true);
            } else {
              this.$notify(res.msg);
            }
          });
        },
      });
    },    
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../src/assets/scss/_handle.scss";
$t-mf: "PingFang";
$t-f: "DINAlternate-Bold";
.popup {
  width: 686px;
min-height: 445px;
background: #FFFFFF;
border-radius: 4px;

  .stop {
    height: 69px;
   border-bottom: 1px solid #DCDEE0;
     .title {
    text-align: left;
    margin-bottom: 32px;
    font-size: 19px;
font-weight: 500;
color: #323233;
line-height: 69px;
margin-left: 32px;
  }
  }
 
  .box{
    padding: 29px 0 19px;
    .item{
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 24px ;
      .laber{
font-size: 19px;
font-weight: 400;
color: #323233;
line-height: 27px;
min-width: 100px;
text-align: left;
      }
      .right{
        width: 480px;
        background: #FFFFFF;
        display: flex;
        flex-direction: column-reverse;
    color: black;
        input{
          padding: 0 20px;
          width: 100%;
          border: none;
          height: 50px;
          font-size: 19px;
          color: #030304;
           border: 1px solid #DCDEE0;
          border-radius: 3px;
        }
        .txear{
           width: 100%;
          height: 100%;
          min-width: 480px;
          max-width: 480px;
           min-height: 200px;
         border: 1px solid #DCDEE0;
           font-size: 19px;
             padding:  20px;
             color: #030304;
             border-radius: 3px;

        }
        .fname{
          text-align: left;
          margin: 10px ;
        }
        .sbtn{
          min-width: 88px;
           font-size: 14px;
    font-family: $t-mf;
    font-weight: bold;
    color: #ffffff;
    line-height: 46px;
    margin: 10px;
 min-width: 99px;
height: 43px;
background: #2163F8;
border-radius: 3px;
border: none;
text-align:center;
cursor: pointer;
        }
      }
      .sright{
        display: flex;
        align-items: center;
        justify-content: space-between;
         width: 480px;
         height: 50px;
         .datetime{
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 200px;
position: relative;
border-radius: 3px;
border: 1px solid #DCDEE0 ;
height: 43px;
padding: 0 16px;
          .tsxt{
           font-size: 19px;
font-weight: 400;
color: #323233;
line-height: 27px;
          }
         }
         .datetime:hover{
          border: 1px solid #2163F8;
          box-shadow: 0px 0px 5px 0px rgba(10,42,97,0.2);
          cursor: pointer;
         }
         .popp{
          position: absolute;
          top: 0;
          width: 100%;
          background: #d7ddeb;
         }
         .txt{
          font-size: 19px;
font-weight: 400;
color: #323233;
line-height: 27px;
         }
      }
    }
  }

   .smbottom {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 22px;
      .btn {
    font-size: 19px;
    font-family: $t-mf;
    font-weight: bold;
    color: #ffffff;
    line-height: 46px;
    margin: 10px;
 min-width: 99px;
height: 43px;
background: #2163F8;
border-radius: 3px;
border: none;
  }
      .qbtn {
     background: #FFFFFF;
border-radius: 3px;
border: 1px solid #DCDEE0;
color: #030304;
    }
  }
}

 .h5{
    width: 100%;
    .stop{
      height: 88px;
      .title{
        font-size: 28px;
      }
    }
    .box{
      .item{
        .laber{
          font-size: 22px;
          min-width: 160px;
        }
        .right{
          .el-select{
             height: 68px;
            line-height: 68px;
            }
                ::v-deep .el-input__inner{
                height: 68px;
                line-height: 68px;
              }
            
          input{
            height: 68px;
            line-height: 68px;
          }
          .sbtn{
            height: 88px;
            width: 160px;
            border-radius: 8px;
          }
          .txear{
            max-width: 100%;
          }
        }
      }
    }
      ::v-deep .el-date-editor.el-input{
                width: 100% !important;
              }
              .smbottom{
                .btn{
                  height: 68px;
                  width: 160px;
                  font-size: 28px;
                }
              }

  }
</style>
