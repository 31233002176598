<template>
  <van-popup
    v-model="shows"
    round
    :position="isH5?'bottom':'center'"
    @close="close"
    :close-on-click-overlay="false"
    :closeable="!loading"
  >
    <div class="popup" :class="isH5?'h5':''">
      <div class="stop">
      <div class="title">公函申请</div>
      </div>
      <div class="box">
   
              <div class="item">
          <div class="laber">工作日期：</div>
          <div class="right">
            <input type="text"  readonly v-model="ftime">
          </div>
        </div>
            <div class="item">
          <div class="laber">案件类型：</div>
          <div class="right">
            <!-- <input type="text"  v-model="type"> -->
                 <el-select v-model="type" clearable 
           style="width:100%"
            filterable
            remote
            :remote-method="getSelectListType"
            placeholder="请输入选择"  >
            <el-option
              v-for="(item,i) in seListType"
              :key="i"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
          </div>
        </div>
              <div class="item">
          <div class="laber">委托人：</div>
          <div class="right">
            <input type="text"  v-model="weituoren">
          </div>
        </div>
              <div class="item">
          <div class="laber">对方当事人：</div>
          <div class="right">
            <input type="text"  v-model="duifang">
          </div>
        </div>
              <div class="item">
          <div class="laber">受理单位：</div>
          <div class="right">
            <input type="text"  v-model="danwei">
          </div>
        </div>
              <div class="item">
          <div class="laber">审理程序：</div>
          <div class="right">
                   <el-select v-model="chengxu" clearable 
           style="width:100%"
            filterable
            remote
            :remote-method="getSelectList"
            placeholder="请输入选择"  >
            <el-option
              v-for="(item,i) in seList"
              :key="i"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
          </div>
        </div>
              <div class="item">
          <div class="laber">案由：</div>
          <div class="right">
            <!-- <input type="text"  v-model="anyou"> -->
                 <el-select v-model="anyou" clearable 
           style="width:100%"
            filterable
            remote
            :remote-method="getSelectListAnyou"
            placeholder="请输入选择"  >
            <el-option
              v-for="(item,i) in seListAnyou"
              :key="i"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
          </div>
        </div>
  <div class="item">
          <div class="laber">案号：</div>
          <div class="right">
            <input type="text"  v-model="anhao">
          </div>
        </div>
        
              <div class="item">
          <div class="laber">案件简介：</div>
          <div class="right">
            <textarea v-model="detail" id="" cols="30" rows="10"  placeholder="请填写案件简介" class="txear"></textarea>
          </div>
        </div>

           <div class="item">
          <div class="laber">上传附件</div>
          <div class="right">
            <div v-for="(item,i) in file_list" :key="i" class="fname">{{ item.file_name }}</div>
              <van-uploader accept="." :after-read="upfiles">
          <van-button icon="plus" class="sbtn" type="primary"  >上传</van-button>
        </van-uploader>
          </div>
        </div>
      </div>
      <div class="smbottom">
         <van-button
          :loading="loading"
          type="primary"
          @click="close"
          class="btn qbtn"
        >
          取消</van-button
        >
        <van-button
          :loading="loading"
          type="primary"
          @click="Upadata"
          class="btn"
          loading-text="Loading..."
        >
          提交</van-button
        >
      </div>
    </div>
  </van-popup>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      isH5:false,
      loading: false,
      myFht: 0,
      myTrx: 0,
      shows: false,
      free: 0,
      value: 75,
      active: 1,
      sqnum: 0,
      reshash:'',
      resID: {
        hase:''
      },
      task: "",
      hase: '',
      issq: false,
      currentDate:new Date(),
      formTime: '',
      toTime:'',
      tshow: false,
      tshows: false,
      detail: '',
      type: "",
      weituoren: "",
      duifang: "",
      danwei: "",
      chengxu: "",
      anyou: "",
      anhao:"",
      file_list: [],
      seList: [],
      seListType: [],
      seListAnyou:[]
      
    };
  },
  watch: {
    show(val) {
      this.shows = val;
      if (val) {
        this.detail = ''
        this.duifang=''
        this.weituoren=''
        this.danwei=''
        this.chengxu=''
        this.anyou=''
        this.anhao=''
        this.type=''
        this.duifang=''
        this.file_list = [],
         this.isH5 = this.$store.getters.phone;
         this.getSelectList()
         this.getSelectListAnyou()
         this.getSelectListType()
      }
    },
  },
  
    computed:{
    ftime() {
         var dd = new Date();
            var y = dd.getFullYear();
      var m = dd.getMonth() + 1;//获取当前月份的日期 
      var d = dd.getDate();
             if (d < 10) {
                d = '0' + d;
      }
      return y+ '-' + m + '-' + d
     },
 },
  methods: {
    getSelectListType() { 
       this.$post({
          url: "/getApplyType",
          data: {},
          callback: (res) => {
            this.$nextTick(function () {
              if (res.code == 200) {
                 this.seListType=res.data
              } else {
                this.$notify(res.msg);
              }
            });
          },
        });
    },
      getSelectListAnyou() { 
       this.$post({
          url: "/getApplyAnyou",
          data: {},
          callback: (res) => {
            this.$nextTick(function () {
              if (res.code == 200) {
                 this.seListAnyou=res.data
              } else {
                this.$notify(res.msg);
              }
            });
          },
        });
    },
      getSelectList() { 
       this.$post({
          url: "/getShenLi",
          data: {},
          callback: (res) => {
            this.$nextTick(function () {
              if (res.code == 200) {
                 this.seList=res.data
              } else {
                this.$notify(res.msg);
              }
            });
          },
        });
    },
          upfiles(file) { 
      let form = new FormData()
      form.append('file', file.file)
        this.$post({
          url: "/upFile",
          data: form,
          callback: (res) => {
            this.$nextTick(function () {
              this.loading = false;              
                console.log(res);
              if (res.code == 200) {
               let files = { 'file_name': file.file.name, 'file_path': res.msg }
                 this.file_list.unshift(files)
              } else {
                this.$notify(res.msg);
              }
            });
          },
        });
    },
    confirm(e) {
    
        var dd = new Date(e);
              var y = dd.getFullYear();
      var m = dd.getMonth() + 1;//获取当前月份的日期 
      var d = dd.getDate();
             if (d < 10) {
                d = '0' + d;
      }
      console.log(y, m);
      this.data.start_time = y + '-' + m + '-' + d
      this.tshow=false
     },
    confirms(e) {
         var dd = new Date(e);
              var y = dd.getFullYear();
      var m = dd.getMonth() + 1;//获取当前月份的日期 
      var d = dd.getDate();
             if (d < 10) {
                d = '0' + d;
      }
      console.log(y, m);
      this.data.end_time = y + '-' + m + '-' + d
      this.tshows=false
     },
    close() {
      this.$emit("tshow", false);
    },
    toclose() {
      this.active=1
      this.$emit("tshow", false);
      this.$emit("updata", true);
    },
    
    Upadata() {     
      if (!this.detail) { return }
         this.loading = true
        this.$post({
          url: "/apply",
          data: {
            reason: this.detail,
            type: this.type,
            weituoren: this.weituoren,
            duifang: this.duifang,
            danwei: this.danwei,
            chengxu: this.chengxu,
            anyou: this.anyou,
            anhao: this.anhao,
            file_list:this.file_list
          },
          callback: (res) => {
            this.$nextTick(function () {
              this.loading = false;
              if (res.code == 200) {
                 this.detail=''
                this.$emit("tshow", false);
                this.$emit("updata", true);
              } else {
                this.$notify(res.msg);
              }
            });
          },
        });
      
        

    },    
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../src/assets/scss/_handle.scss";
$t-mf: "PingFang";
$t-f: "DINAlternate-Bold";
.popup {
  width: 686px;
min-height: 445px;
background: #FFFFFF;
border-radius: 4px;

  .stop {
    height: 69px;
   border-bottom: 1px solid #DCDEE0;
     .title {
    text-align: left;
    margin-bottom: 32px;
    font-size: 19px;
font-weight: 500;
color: #323233;
line-height: 69px;
margin-left: 32px;
  }
  }
 
  .box{
    padding: 29px 0 19px;
    .item{
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 24px ;
      .laber{
font-size: 19px;
font-weight: 400;
color: #323233;
line-height: 27px;
min-width: 120px;
text-align: left;
      }
      .right{
        width: 480px;
        background: #FFFFFF;
  width: 480px;
        background: #FFFFFF;
        display: flex;
        flex-direction: column-reverse;
    color: black;
      
        .fname{
          text-align: left;
          margin: 10px ;
        }
        .sbtn{
          min-width: 88px;
           font-size: 14px;
    font-family: $t-mf;
    font-weight: bold;
    color: #ffffff;
    line-height: 46px;
    margin: 10px;
 min-width: 99px;
height: 43px;
background: #2163F8;
border-radius: 3px;
border: none;
text-align:center;
cursor: pointer;
        }
        .sinput{
            padding: 0 20px;
          width: 100%;
          height: 40px;
          font-size: 19px;
          color: #030304;
          border: 1px solid #DCDEE0;
          border-radius: 3px;
          text-align: left;
          line-height: 40px;
        }
        input{
          padding: 0 20px;
          width: 100%;
          height: 40px;
          font-size: 19px;
          color: #030304;
          border: 1px solid #DCDEE0;
          border-radius: 3px;
        }
        .txear{
          width: 100%;
          height: 100%;
            min-width: 480px;
          max-width: 480px;
          min-height: 200px;
         border: 1px solid #DCDEE0;
           font-size: 19px;
             padding: 20px;
             color: #030304;
             border-radius: 3px;
        }
      }
      .sright{
        display: flex;
        align-items: center;
        justify-content: space-between;
         width: 480px;
         height: 50px;
         .datetime{
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 200px;
position: relative;
border-radius: 3px;
border: 1px solid #DCDEE0 ;
height: 43px;
padding: 0 16px;
          .tsxt{
           font-size: 15px;
font-weight: 400;
color: #323233;
line-height: 27px;
white-space: nowrap;
          }
         }
         .datetime:hover{
          border: 1px solid #2163F8;
          box-shadow: 0px 0px 5px 0px rgba(10,42,97,0.2);
          cursor: pointer;
         }
         .popp{
          position: absolute;
          top: 0;
          width: 100%;
          background: #d7ddeb;
         }
         .txt{
          font-size: 19px;
font-weight: 400;
color: #323233;
line-height: 27px;
         }
      }
    }
  }

   .smbottom {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 22px;
      .btn {
    font-size: 19px;
    font-family: $t-mf;
    font-weight: bold;
    color: #ffffff;
    line-height: 46px;
    margin: 10px;
 min-width: 99px;
height: 43px;
background: #2163F8;
border-radius: 3px;
border: none;
  }
      .qbtn {
     background: #FFFFFF;
border-radius: 3px;
border: 1px solid #DCDEE0;
color: #030304;
    }
  }

}

  .h5{
    width: 100%;
    .stop{
      height: 88px;
      .title{
        font-size: 28px;
      }
    }
    .box{
      .item{
        .laber{
          font-size: 22px;
          min-width: 160px;
        }
        .right{
          .el-select{
             height: 68px;
            line-height: 68px;
            }
                ::v-deep .el-input__inner{
                height: 68px;
                line-height: 68px;
              }
            
          input{
            height: 68px;
            line-height: 68px;
          }
          .sbtn{
            height: 88px;
            width: 160px;
            border-radius: 8px;
          }
          .txear{
            max-width: 100%;
          }
        }
      }
    }
      ::v-deep .el-select-dropdown__item{
                font-size: 28px !important;
                height: 68px !important;
              }
              .smbottom{
                .btn{
                  height: 68px;
                  width: 160px;
                  font-size: 28px;
                }
              }

  }
</style>
