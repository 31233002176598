<template>
  <div class="home" :class="show ? 'h5' : ''">
    <div class="content">
      <div class="title">
        咨亲OA管理系统
        <!-- <img src="../assets/img/bt@2x.png" alt="" srcset="" > -->
      </div>
      <div class="box">
        <div class="tip">欢迎使用</div>
        <div class="sinput">
          <input type="text" placeholder="请输入您的账号" v-model="phone" >
        </div>
      <div class="sinput">
          <input type="password" placeholder="请输入您的密码" v-model="password">
        </div>
    <van-button
          :loading="loading"
          type="primary"
          @click="toLogin"  
          class="btn"
          loading-text="Loading..."
        >
          登陆</van-button
        >
        
      </div>
    
      
    </div>
  </div>
</template>

<script>

export default {
  name: "Home",
  components: {
   
  },
  data() {
    return {
      show:false,
      loading: false,
      password: "",
      phone: ""
    };
  },
  filters: {
    yingcang(val) {
      if (val) {
        let a = val.replace(/(\w{4})\w+(\w{4})/, "$1...$2");
        return a;
      } else {
        return "";
      }
    },
  },
  created() {
    // console.log(this.userWallet);
    if(this.userWallet){
    let data=JSON.parse(this.userWallet)
    this.password=data.password
    this.phone=data.username
    }
  },
  mounted() {
    if (this._isMobile()) {
      this.show = true;
    } else {
      this.show = this.$store.getters.phone;
    }
  },

  computed: {
    userWallet() {
      if (this.$store.getters.userWallet) {
        return this.$store.getters.userWallet;
      } else {
        return "";
      }
    },
  },
  watch: {
    "$store.getters.phone": function (val) {
      this.show = val;
    },  
    deep: true,
    immediate: true,
  },

  methods: {   
     _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pod|iPhone|iPod|ios|Android|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },

    toLogin() { 
      if (this.phone && this.password) {
        this.loading = true

        this.$post({
          url: "v1/Login",
          data: {
            username: this.phone,
            password: this.password,
            type: 1
          },
          callback: (res) => {
            this.$nextTick(function () {
              this.loading = false;
              if (res.code == 200) {
               this.$router.replace({ path: '/' })
                this.$store.commit("token", res.data.token);
                this.$store.commit("userWallet", JSON.stringify({ username: this.phone,password: this.password}));
              } else {
                // console.log(res.msg);
                this.$toast(res.msg);
              }
            });
          },
        });
      } else {
         this.$notify('请输入用户名或密码');
      }
    },

  },
};
</script>

<style lang="scss" scoped>
@import "../../src/assets/scss/_handle.scss";

$t-mf: "PingFang";
$t-f: "fBold";
$t-s: "S5ae";

.home {
  background: url("../assets/img/bbg.png") no-repeat;
  background-size: cover;
  min-height: 100vh;  
  display: flex;
  align-items: center;
  justify-content: center;
  .content{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .title{
     min-width: 365px;
height: 69px;
      font-size: 49px;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 500;
color: #000000;
line-height: 69px;
      img{
        width:100%;
        height:100%;
      }
      }
      .box{
        width: 530px;
height: 376px;
background: #FFFFFF;
box-shadow: 0px 0px 12px 0px rgba(7,5,34,0.2);
border-radius: 4px;
margin-top: 40px;
  display: flex;
    align-items: flex-start;
    flex-direction: column;
padding: 56px;
    .tip{
      font-size: 24px;
font-weight: 500;
color: #000000;
line-height: 33px;
text-shadow: 0px 0px 12px rgba(7,5,34,0.2);
text-align: left;
margin-bottom:12px;
    }
    .sinput{
      width:100%;
      margin:12px 0;
      height: 45px;
background: #FFFFFF;
box-shadow: 0px 0px 12px 0px rgba(7,5,34,0.2);
border-radius: 2px;
border: 1px solid #DCDEE0;
input{
  width:100%;
  height:100%;
  border:none;
  padding:0 22px;
  color:#333333;
  font-size:14px;
}
    }
    .btn{
      width: 415px;
height: 45px;
background: #445AF7;
box-shadow: 0px 0px 12px 0px rgba(7,5,34,0.2);
border-radius: 2px;
border: 1px solid #DCDEE0;
margin-top:28px;
    }
      }
    
  }
}
.h5 {
  .content{
    width: 100%;
    .box{
      width: auto;
      margin: 28px;
      height: 100%;
      .tip{
        font-size: 36px;
      }
    }
  }
 
}
</style>
