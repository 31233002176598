<template>
  <div class="">
  <div class="home" v-if="!show">
    <div class="left">
      <div class="logo">咨亲系统</div>
      <div class="user">
        <div class="prcimg" >
            <van-uploader  :after-read="upfiles">
          <img :src="user.portrait||portrait" alt="" srcset="" class="prcimg">
          </van-uploader>
        </div>
        <div class="uname">
          <input type="text" v-model="user.nickname" :readonly="xiugai" @click="xiugai=false">  
          <div class="btn" v-if="!xiugai" @click="updatas">确定</div>
        </div>
        <div class="uid">{{user.lvshihao}}</div>
        <div class="address">{{user.lvsuo_name}}</div>
      </div>
      <div class="solar">
<!-- <van-calendar
  title="日历"
  :poppable="false"
  :show-confirm="false"
  :style="{ height: '500px',color:'#333333' }"
/> -->
<calendar/>
      </div>

      <div class="course">


      </div>
    </div>
    <div class="right">
      <div class="srtop">
        <div class="name">{{user.nickname }}</div>
        <!-- <img src="../assets/img/icon-jiantou-bai@2x.png" class="down"> -->
        <tips data="退出"/>
      </div>
      <div class="stop">
        <!-- <div class="title">{{ title }}</div> -->
        <div class="time">{{ time }}</div>
      </div>

      <div class="tabbox">
        <div class="sitetm bg1" @click="towkshow">
            <img src="../assets/img/icon-gzhb.png" class="icon">
            <div class="txt">行政工作</div>
        </div>
          <div class="sitetm bg2" @click="toghshow">
            <img src="../assets/img/icon-ghjd.png" class="icon">
            <div class="txt">公函管理</div>
        </div>
          <div class="sitetm bg3" @click="tosetshow">
            <img src="../assets/img/icon-gxwd.png" class="icon">
            <div class="txt">共享文档</div>
        </div>
      </div>

      <div class="taskbox">
        <div class="mtop">
          <div class="title"><span></span> 我的任务 
            <div class="seach">
            <input type="text" v-model="keyword" class="input" placeholder="请输入关键词">
            <div class="block">
        <el-date-picker
      v-model="Datetime"
      type="daterange"
      align="right"
      unlink-panels
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
      value-format="yyyy-MM-dd"
      :picker-options="pickerOptions2">
    </el-date-picker>
          </div>
           <el-button type="primary" plain @click="toSearch">搜索</el-button>
          </div>
        </div>
          
          <div class="more" @click="getList()">刷新  <img src="../assets/img/sx.png" class="ico" :class="loading?'rund':''"> </div>
        </div>
        <div class="list">
          <div class="item" v-for="(item, i) in list" :key="i">
            <div class="sleft" @click="toInfo(item) ">
              <div class="title">
                <span class="red" :style="'background:'+item.label_color" v-if="item.label_title">{{ item.label_title }}</span>                
                {{item.title}}</div>
              <div class="time">{{user.nickname}} ｜ {{item.start_time}} 至 {{item.end_time}}</div>
            </div>
            <div class="sright"><img src="../assets/img/icon-more.png" class="ico"> </div>
          </div>
        </div>

      </div>

    </div>
</div>
<div class="H5" v-else>
  <div class="stop">
        <!-- <div class="title">{{ title }}</div> -->
        <div class="time">{{ time }}</div>
      </div>
  <div class="top">
    <div class="left">
       <div class="prcimg" >
            <van-uploader  :after-read="upfiles">
          <img :src="user.portrait||portrait" alt="" srcset="" class="prcimg">
          </van-uploader>
        </div>
    </div>
    <div class="right">
        <div class="uname">
         <div class="input">{{ user.nickname }}</div> | <div class="uid">{{user.lvshihao}}</div>
        </div>       
        <div class="address">{{user.lvsuo_name}}</div>
    </div>
    <div class="quot">  <tips data="退出"/></div>
  
  </div>

  <div class="box">
    <div class="item">
      <img src="../assets/img/icon-gzhb@2x.png" alt="" srcset="" @click="towkshow">
      <div class="txt">行政工作</div>
    </div>
       <div class="item">
      <img src="../assets/img/icon-ghjd@2x.png" alt="" srcset="" @click="toghshow">
      <div class="txt">公函管理</div>
    </div>
       <div class="item">
      <img src="../assets/img/icon-gxwd@2x.png" alt="" srcset="" @click="tosetshow">
      <div class="txt">共享文档</div>
    </div>
  </div>
  <calenH5/>
 

  <div class="card">

   <div class="stop">
      <div class="title">我的任务</div>
      <div class="more"><img src="../assets/img/icon-search@2x.png" alt="" srcset="" class="ss" @click="tosece">
          
      </div>
    </div>
      <div class="list">
          <div class="item" v-for="(item, i) in list" :key="i">
            <div class="sleft" @click="toInfo(item) ">
              <div class="title">
                <span class="red" :style="'background:'+item.label_color" v-if="item.label_title">{{ item.label_title }}</span>                
                {{item.title}}</div>
              <div class="time">{{user.nickname}} ｜ {{item.start_time | ftime}} 至 {{item.end_time | ftime}}</div>
            </div>
            <!-- <div class="sright"><img src="../assets/img/icon-more.png" class="ico"> </div> -->
          </div>
        </div>
  </div>
   


</div>

    <workReport
      :show="wkshow"
      :data="plandata"
      @tshow="
        (val) => {
          wkshow = val;
        }"
      @updata="
        (val) => {
          wkshow = false;
        }
      "
    />

     <missive
      :show="ghshow"
      :data="plandata"
      @tshow="
        (val) => {
          ghshow = val;
        }"
      @updata="
        (val) => {          
          ghshow = false;
        }
      "
    />

    <upfile
    :show="setShow"
    :data="plandata"
      @tshow="
        (val) => {
          setShow = val;
        }"

          @updata="
        (val) => {
          setShow=false
        }
      "
    />


     <sInfo
    :show="sinfoShow"
    :data="info"
      @tshow="
        (val) => {
          sinfoShow = val;
        }"

          @updata="
        (val) => {
          sinfoShow=false
        }
      "
    />
    <search
     :show="seaShow"
    :data="info"
      @tshow="
        (val) => {
          seaShow = val;
        }"

          @updata="
        (val) => {
          seaShow=false
          toseach(val)
        }
      "
    />  
  </div>
</template>

<script>

import workReport from '../components/workReport.vue'
import sInfo from '../components/sinfo.vue'
import missive from '../components/missive.vue'
import upfile from '../components/upfile.vue'
import tips from '../components/tips.vue'
import search from '../components/search.vue'
import calendar from '../components/calendar.vue'
import calenH5 from '../components/calerh5.vue'
export default {
  name: "Home",
  components: {
    workReport,
    sInfo,
    missive,
    upfile,
    tips,
   search,calendar,calenH5
  },
  data() {
    return {
      show: false,
      title: "",
      time: "",
      list: [],
      loading: true,
      wkshow: false,
      ghshow: false,
      setShow:false,
      plandata: {},
      sinfoShow: false,
      info: {},
      user: {},
      portrait: require('@/assets/img/tx.png'),
      xiugai: true,
      keyword: '',
      Datetime: [],
      start_time:'',
      end_time: "",
     
      seaShow:false,
       pickerOptions2: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
      
      
    };
  },
  filters: {
    yingcang(val) {
      if (val) {
        let a = val.replace(/(\w{4})\w+(\w{4})/, "$1...$2");
        return a;
      } else {
        return "CONNECT WALLET";
      }
    },
    ftime(val) { 
     let a = val.replace(/(\w{10})\w/, "$1");
        return a;
    },
  },
  created() {
    this.getInfo();
    this.getList();
  },
  mounted() {
    if (this._isMobile()) {
      this.show = true;
    } else {
      this.show = this.$store.getters.phone;
    }
  },

  computed: { 
      userName() {
      if (this.$store.getters.userWallet) {
     let name= JSON.parse(this.$store.getters.userWallet).nickname
        return name
      } else {
        return "";
      }
    },
    dqyy() {
      if (this.$store.getters.language) {
        return this.$store.getters.language;
      } else {
        return "zh-CN";
      }
    },
  },
  watch: {
    "$store.getters.phone": function (val) {
      this.show = val;
    },
    "$store.getters.userWallet": function () {
      this.getmyBalance(this.fromdata);
    },
    deep: true,
    immediate: true,
  },

  methods: {
    tosece() { 
      if (this.seaShow) { this.seaShow = false }
      setTimeout(() => {
        this.seaShow=true
      }, 100);
    },
  
    toseach(e) {
      this.keyword = e.keyword
      this.start_time = e.start_time
    this.end_time=e.end_time
   this.getList()
     },
    tocatday(e) {
      this.actDay=e
     },
    toSearch() { 
      if (this.Datetime.length > 0) {
        this.start_time = this.Datetime[0]
      this.end_time=this.Datetime[1]
      }
     this.getList()
    },
     upfiles(file) { 
      console.log(file);
      let form = new FormData()
      form.append('file', file.file)
        this.$post({
          url: "/upFile",
          data: form,
          callback: (res) => {
            this.$nextTick(function () {
              this.loading = false;              
                console.log(res);
              if (res.code == 200) {
                this.user.portrait=res.msg
                this.updatas()
              } else {
                this.$notify(res.msg);
              }
            });
          },
        });
    },
       updatas() {
         this.$post({
          url: "v1/setMemberInfo",
          data: {
            nickname: this.user.nickname,
            portrait:this.user.portrait
          },
          callback: (res) => {
            this.$nextTick(function () {
              if (res.code == 201) {
                this.xiugai=true
              } else {
                this.$notify(res.msg);
              }
            });
          },
        });
     },
    toInfo(e) {
      this.info = e.info
      this.info.label_title = e.label_title
      this.info.label_color = e.label_color
      if (this.sinfoShow) { this.sinfoShow = false }
      setTimeout(() => {
         this.sinfoShow=true
      }, 100);
     },
    towkshow() {
      if (this.wkshow) { this.wkshow = false }
      setTimeout(() => {
         this.wkshow=true
      }, 100);
     
    },
    toghshow() { 
      if(this.ghshow){this.ghshow=false}
         setTimeout(() => {
         this.ghshow=true
      }, 100);
    },
      tosetshow() { 
      if(this.setShow){this.setShow=false}
         setTimeout(() => {
         this.setShow=true
      }, 100);
    },
    
    getInfo() { 
     
          this.time=this.getDateStr()
        //  this.$get({
        // url: "v1/getConfigDetail",
        // data: { name:'slogan' },
        // callback: (res) => {
        //   this.$nextTick(function () {
        //     this.title = res.data[0].value
        //   });
        // },
        //  });
          this.$post({
        url: "v1/user",
        data: {},
        callback: (res) => {
          this.$nextTick(function () {
            if (res.code == 200) {
              this.user = res.data
            }
            if (res.msg == '缺少Token参数'||res.msg=='Token已过有效期' ||res.msg=='Token验证失败') {
                this.$store.commit("token", '');
                  this.$store.commit("userWallet", '');
                 this.$router.replace({ path: '/Login' })
            }
          });
        },
      });
    },
    getList() { 
      this.loading=true
              var dd = new Date();
              var y = dd.getFullYear();
              var m = dd.getMonth() + 1;//获取当前月份的日期 
         this.$post({
        url: "calendar",
        data: { month :y+'-'+m,keyword:this.keyword,start_time:this.start_time,end_time:this.end_time},
        callback: (res) => {
          this.$nextTick(function () {
            this.loading=false
            this.list = res.data.event_list 
          });
        },
      });
    },
    getDateStr() {//获取当天日期
              var dd;
              dd = new Date();
              var y = dd.getFullYear();
              var m = dd.getMonth() + 1;//获取当前月份的日期              
              var d = dd.getDate();            
              if (d < 10) { d = '0' + d;}
      this.day = d
              this.actDay=d
               var weekday = new Array(7);
              weekday[0] = "星期日";
              weekday[1] = "星期一";
              weekday[2] = "星期二";
              weekday[3] = "星期三";
              weekday[4] = "星期四";
              weekday[5] = "星期五";
              weekday[6] = "星期六";
              var week = weekday[dd.getDay()];
              return y+'-'+m + '-' + d  +week;
    },
  
    tosset() {
      if (this.setShow) { this.setShow = false }
      this.setShow=true
    },
    addldx() { 
       if(this.ldxshow){this.ldxshow=false}
       if (this.ldxUsdtnum > 0) {
        this.plandata = {
          num: (this.ldxUsdtnum * 1).toFixed(4),
          tnum:(this.ldxJxtnum * 1.1).toFixed(4),
          from: this.fromdata.cname,
          fico: this.fromdata.ico,
          fid: this.fromdata.chaiID,
          vna: this.fromdata.vna,
          tid: this.todata.chaiID,
          to: this.todata.cname,
          tico: this.todata.ico,
          tvna: this.todata.vna,
        };
        this.ldxshow = true;
      }
    },

     toshow(e) {
      this.type = e;
    },

 
    togetdata(e) {
      if (e.show) {
        return;
      }
      this.bxshows = false;
      this.todata = e;
    },

    tomax() {
      this.buynum = this.mynum;
    },
    jsnum() {
      setTimeout(() => {
        this.buynum = this.buynum - this.mynum > 0 ? this.mynum : this.buynum;
      }, 100);
    },
    topage(e) {
      if (this.reactShow) { this.reactShow = false }
        this.reactdata=e
      this.reactShow=true
    },
    mrsrright() {
      clearInterval(this.time);
      this.time = setInterval(() => {
        this.slock = this.slock - 1;
        if (this.slock < -2410) {
          this.slock = 0;
        }
      }, 5);
    },
    mrsrleft() {
      clearInterval(this.time);
      this.time = setInterval(() => {
        this.slock = this.slock + 1;
        if (this.slock > 0) {
          this.slock = -2410;
        }
      }, 5);
    },
    mromver() {
      setTimeout(() => {
        clearInterval(this.time);
      }, 200);
    },

    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pod|iPhone|iPod|ios|Android|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    buys() {
      if(this.exshow){this.exshow=false}
     this.exshow = true;
      if (!this.todata.chaiID) {
        this.$toast("请选择目标链");
        return;
      }
      if (this.fromdata.chaiID == this.todata.chaiID) {
        return;
      }
      if (this.buynum > 0) {
        this.plandata = {
          num: (this.buynum * 1).toFixed(4),
          tnum:(this.buynum * 1.1).toFixed(4),
          from: this.fromdata.cname,
          fico: this.fromdata.ico,
          fid: this.fromdata.chaiID,
          vna: this.fromdata.vna,
          tid: this.todata.chaiID,
          to: this.todata.cname,
          tico: this.todata.ico,
          tvna: this.todata.vna,
        };
        this.exshow = true;
      }
        console.log(this.exshow);
    },
 


    toopen(url) {
      window.open(url);
    },


    up() {
      if (this.sqloading) {
        return;
      }
      this.buynum =
        this.buynum >= this.maxmint ? this.maxmint : Number(this.buynum) + 1;
    },
    dow() {
      if (this.sqloading) {
        return;
      }
      this.buynum = this.buynum <= 1 ? 1 : Number(this.buynum) - 1;
    },

    async getUrl(e) {
      // let gettoken=await gettokenURI(e).then(res=>{return res})
      // let urls =e+'.json'
      // this.$axios({
      //           method: "GET",
      //           url: urls,
      //         }).then((response) => {
      //           this.list[e] = response.data;
      //         console.log("list",this.list);
      //         this.sqloading=false
      //           this.$forceUpdate();
      //         });
      //         return
      // let gettoken='https://bafybeiebykx3fq5w4o6ll46dkirzlveskychoykadml7owohmgpjuj3xjq.ipfs.nftstorage.link/'
      let data = {
        id: e,
        // image:gettoken+(e-1)+'.png',
        name: "Okay Bear #" + e,
        // url:`https://apenft.io/assets/TPJEJuezYzTe6yJ7rU4mMLa9abYJJpboE1/`+e
        url:
          `https://testnet.apenft.io/assets/TNZ8J5Z3AN1hj4FPrSHhefuT1XMZTNcP8y/` +
          e,
      };
      // console.log(data);
      this.list[e] = data;
      this.sqloading = false;
      this.$forceUpdate();
      // this.$axios({
      //         method: "GET",
      //         url: gettoken,
      //       }).then((response) => {
      //         this.list[e] = response.data;
      //       console.log("list",this.list);
      //       this.sqloading=false
      //         this.$forceUpdate();
      //       });
    },

  },
};
</script>

<style lang="scss" scoped>
@import "../../src/assets/scss/_handle.scss";

$t-mf: "PingFang";
$t-f: "fBold";
$t-s: "S5ae";

.home {
  background: #EEF2FB url("../assets/img/bk@2x.png") no-repeat;
  background-size: 100% auto;
  min-height: 100vh;
  min-width: 1440px;
  display: flex;

  .left{
    width: 348px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    .logo{
      margin: 20px;
font-size: 24px;
font-weight: 500;
color: #FFFFFF;
line-height: 33px;
    }
    .user{
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin-top: 93px;
      margin-bottom: 10px;
      .prcimg{
        width: 94px;
height: 94px;
border-radius: 100%;
background: #FFFFFF;
      }
      .uname{
        font-size: 18px;
font-weight: 500;
color: #000000;
line-height: 25px;
margin: 17px 0 5px;
display: flex;
align-items: center;
input{
  border: none;
  background: transparent;
  text-align: center;
}
.btn{
  font-size: 12px;
  border: 1px solid #1d773d;
      border-radius: 8px;
    padding: 0 10px;
    cursor: pointer;
}
      }
      .uid{
        font-size: 12px;
font-weight: 400;
color: #000000;
line-height: 17px;
margin:5px 0;
      }
      .address{
        font-size: 14px;
font-weight: 400;
color: #B0B2B5;
line-height: 20px;
      }
    }

    .solar{
      margin: 0 auto;
    }
  }
  .right{
    flex: 1;
    padding: 22px;
    .srtop{
      display: flex;
      align-items: center;
      justify-content: flex-end;
  
      .name{
        font-size: 12px;
font-weight: 400;
color: #FFFFFF;
line-height: 17px;
      }
      .down{
        width: 15px;
        height: 15px;
        margin-left:10px ;
      }
    }
    .stop{      
      text-align: left;
      height: 198px;
            background: url("../assets/img/people@2x.png") no-repeat;
  background-size: 226px auto;
  background-position: 90% bottom;
      .title{
        font-size: 32px;
font-weight: 500;
color: #FFFFFF;
line-height: 45px;
      padding-top: 54px;
      }
      .time{
        font-size: 24px;
font-weight: 400;
color: #FFFFFF;
line-height: 33px;
margin-top: 12px;
      }
    }
    .tabbox{
      width: 100%;
height: 194px;
background: #FFFFFF;
border-radius: 10px;
display: flex;
align-items: center;
justify-content: space-between;
padding: 22px;
.sitetm{
  width: calc(33% - 12px);
  min-width: 332px;
height: 146px;
border-radius: 10px;
display: flex;
align-items: center;
justify-content: center;
// opacity: 0.12;
cursor: pointer;
.icon{
  width: 66px;
  height: 66px;
}
.txt{
  font-size: 24px;
font-weight: 400;
color: #000000;
line-height: 33px;
margin-left: 24px;
}

}
.sitetm:hover{
  box-shadow: 1px 5px 10px #B0B2B5;
  .txt{
  font-weight: bold;  
  text-shadow: 1px 5px 10px #B0B2B5;
  transition: all 0.3s ;
}
}
.bg1{
  background: #E4ECFE;
}
.bg2{
  background: #E2F6E9;
}
.bg3{
  background: #FFF0E9;
}
    }

    .taskbox{
      width: 100%;
height: 440px;
background: #FFFFFF;
border-radius: 10px;
margin-top: 18px;
overflow: hidden;
.mtop{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 22px;
  .title{
    font-size: 16px;
font-weight: 500;
color: #000000;
line-height: 22px;
display: flex;
align-items: center;
width: 100%;
white-space: nowrap;
span{
 width: 2px;
height: 14px;
background: #445AF7;
  display: inline-block;
  margin-right: 5px;
}

.seach{
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  margin: 0 10%;
  .input{
    padding: 0 20px;
          width: 100%;
          border: none;
          height: 40px;
          font-size: 14px;
          color: #030304;
           border: 1px solid #DCDEE0;
          border-radius: 3px;
  }
  .block{
    min-width: 360px;
    height: 50px;
    margin: 0 2%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    .time{
      width: 162px;
      font-size: 14px;
    }
    .timebox{
      position: absolute;
      top: 10px;
      width: 100%;
      background: #ffffff;
    }
  }
}
  }

  .more{
    font-size: 14px;
font-weight: 400;
color: #435AF7;
line-height: 20px;
display: flex;
align-items: center;
cursor: pointer;
white-space: nowrap;
.ico{
  width: 10px;
  height: 10px;
  margin-left: 5px;
}
.rund{
animation: rotate-ani .5s linear infinite;
}

@keyframes rotate-ani {
from {

transform: rotate(0deg);

}
to {
transform: rotate(360deg);

}
}
  }
}
.list{
  width: 100%;
  padding:0 22px;
  height: 340px;
  overflow: auto;
  .item{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
border: 1px solid #EAF1F4;
padding: 12px;
margin-bottom: 16px ;
cursor: pointer;
    .sleft{
      flex: 1;
      text-align: left;
      .title{
        font-size: 16px;
font-weight: 400;
color: #000000;
line-height: 22px;
margin: 6px 0 ;
display: flex;
align-items: center;
.red{
  color: #ffffff;
  width: 32px;
height: 17px;
background: #3FA765;
border-radius: 2px;
font-size: 10px;
font-weight: 400;
color: #FFFFFF;
line-height: 14px;
margin-right: 10px;
display: flex;
align-items: center;
justify-content: center;
}
.suc{
   color: #ffffff;
  width: 32px;
height: 17px;
background: #3FA765;
border-radius: 2px;
font-size: 10px;
font-weight: 400;
color: #FFFFFF;
line-height: 14px;
margin-right: 10px;
display: flex;
align-items: center;
justify-content: center;
}
      }
      .time{
        font-size: 14px;
font-weight: 400;
color: #B0B2B5;
line-height: 20px;
      }
    }
    .sright{
      .ico{
        width: 10px;
        height: 10px;
      }
    }
  }
  .item:hover{
    opacity: 0.8;
    background: #E2F6E9;
     box-shadow: 1px 5px 10px #B0B2B5;
    transition: all 0.3s ;
  }
}
    }
  }
}
.H5{
    background: #EEF2FB url("../assets/img/h5bg.png") no-repeat;
  background-size: 100% auto;
  min-height: 100vh;
  width: 100%;
  max-width: 750px;
  display: flex;
  flex-direction: column;
  .stop{
    margin-top: 26px;
    font-size: 26px;
  }
  .top{
    display: flex;
    align-items: center;
    height: 110px;
    margin: 20px 38px 46px;
    position: relative;
    box-sizing: border-box;
    .quot{
      // position: absolute;
    }
    .left{
      .prcimg{
        width: 110px;
height: 110px;
background: #ffffff;
  border-radius: 100%;
.img{
  width: 100%;
  height: 100%;
  margin: 2px;
  border-radius: 100%;
}
      }
    }
    .right{
      margin-left: 24px;      
        text-align: left;
        flex: 1;
        .uname{
          display: flex;
          align-items: center;
        }
      .input{
        background: transparent;
        border: none;
        font-size: 32px;
font-weight: 500;
color: #FFFFFF;
margin-right: 10px;
line-height: 44px;
      }
      .uid{
        margin-left: 10px;
        color: #FFFFFF;
      }

      .address{
font-weight: 400;
color: #FFFFFF;
line-height: 40px;
opacity: 0.8;
margin-top: 12px;
      }
    }
  }
  .box{
    width: 678px;
height: 218px;
background: #FEFEFE;
border-radius: 16px;
margin: 0 auto;
display: flex;
justify-content: space-between;
padding: 24px 68px;
.item{
  display: flex;
  flex-direction: column;
  align-items: center;
  img{
    width: 88px;
    height: 88px;    
    margin: 16px;
  }
  .txt{
    font-size: 24px;
font-weight: 400;
color: #181818;
line-height: 34px;
  }
}
  }
  .card{
    width: 678px;
min-height: 426px;
height: 100%;
background: #FFFFFF;
border-radius: 16px;
margin: 20px auto;

.stop{
  display: flex;
  justify-content: space-between;
  padding: 24px;
  border-bottom: 1px solid  #EAF1F4;
  .title{
font-size: 28px;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 500;
color: #181818;
line-height: 40px;
  }
  .more{
    font-size: 24px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #9FA4BB;
line-height: 34px;
.ss{
  width: 26px;
  height: 26px;
}
  }
}
.xq{
  padding: 24px;
  font-size: 24px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #9FA4BB;
line-height: 34px;
display: flex;
justify-content: space-between;
align-items: center;
}
.day{
    padding: 24px;
  font-size: 28px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #181818;
line-height: 34px;
display: flex;
justify-content: space-between;
align-items: center;
}
.act{
  width: 60px;
height: 60px;
background: #445AF7;
border-radius: 100%;
color: #ffffff;
display: flex;
align-items: center;
justify-content: center;
}
.ii{
  width: 60px;
}
.slist{
  display: flex;
  color: #000000;
  padding: 24px;
  .items{
    position: relative;
    margin-left: 32px;
    padding-left: 20px;
    font-size: 24px;
font-weight: 400;
color: #181818;
line-height: 34px;
  }
  .items::before{
    position: absolute;
    left: -12px;    
    content: "";
    width: 12px;
    top: 12px;
height: 12px;
background: #F12A49;
transform: translate(-50% ,-50%);
border-radius: 100%;
  }
}

.list{
  width: 100%;
  padding:0 24px;
  overflow: auto;
  .item{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
border: 1px solid #EAF1F4;
padding: 12px;
margin: 16px 0 ;
cursor: pointer;
    .sleft{
      flex: 1;
      text-align: left;
      .title{
        font-size:28px;
font-weight: 400;
color: #000000;
line-height: 44px;
margin: 12px 0 ;
display: flex;
align-items: center;
.red{
  color: #ffffff;
  width: 64px;
height: 34px;
background: #3FA765;
border-radius: 2px;
font-size: 20px;
font-weight: 400;
color: #FFFFFF;
line-height: 24px;
margin-right: 12px;
display: flex;
align-items: center;
justify-content: center;
}
.suc{
   color: #ffffff;
  width: 64px;
height: 34px;
background: #3FA765;
border-radius: 2px;
font-size: 20px;
font-weight: 400;
color: #FFFFFF;
line-height: 28px;
margin-right: 12px;
display: flex;
align-items: center;
justify-content: center;
}
      }
      .time{
        font-size: 20px;
font-weight: 400;
color: #B0B2B5;
line-height: 24px;
      }
    }
    .sright{
      .ico{
        width: 10px;
        height: 10px;
      }
    }
  }
  .item:hover{
    opacity: 0.8;
    background: #E2F6E9;
     box-shadow: 1px 5px 10px #B0B2B5;
    transition: all 0.3s ;
  }
}
  }
}

</style>
