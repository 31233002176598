<template>
    <div class="carBox">
        <div class="top">
            <div class="sleft" @click="handleCalendar(0)">
                <img src="../assets/img/icon-2jiantou-left@2x.png" alt="">
                <img src="../assets/img/icon-1jiantou-left@2x.png" alt="" srcset="">
            </div>
            <div class="conte">{{cur_year || "--"}} 年 {{cur_month || "--"}} 月</div>
            <div class="sleft" @click="handleCalendar(1)">
                <img src="../assets/img/icon-2jiantou-right@2x.png" alt="">
                <img src="../assets/img/icon-1jiantou-right@2x.png" alt="" srcset="">
            </div>
        </div>
        <div class="week">
			<div class="cell" v-for="(item,index) in weeks_ch" :key="index">{{item}}</div>
		</div>
        <div class="myDateTable">
            <div v-for="(item,j) in days" :key="j" class='dateCell'>
                <div v-if="item.date==undefined||item.date == null" class='cell'>
					<span :decode="true">&nbsp;&nbsp;</span>
				</div>
                <div class="cell" v-else  @click="setday(item.date)" :class="item.isSign?'bw':''">
                    <div class="i act" v-if="(day==item.date&&toMonth==cur_month)">   {{ item.date }}</div>
                    <div class="i now" v-else-if="item.date==today"> {{ item.date }}</div>
                    <div class="i" v-else>{{item.date }}</div>

                 </div>
            </div>
        </div>
        <div class="add" @click="toAdd()">添加备忘</div>
        <div class="list">
            <div class="items fadeInUp"  v-for="(item,i) in list" :key="i">
                <div class="time">{{item.time_start||"00:00"}} ~ {{item.time_end ||"23:59" }}</div>
                <div class="txt">{{item.content}}</div>
            </div>           
        </div>
      <beiwan  :show="addshow"
    :data="ftime"
      @tshow="
        (val) => {
          addshow = val;
        }"
          @updata="
        (val) => {
          getAll()
          getList()
        }
      "
    />
    </div>

</template>
<script>
import beiwan from '../components/beiwan.vue'
export default {
    components: {
        beiwan
    },
    data() { 
        return {
            days: [],
            SignUp: [],
            cur_year: 0, //当前选的年
			cur_month: 0, //当前选的月
            weeks_ch: ['日', '一', '二', '三', '四', '五', '六'],
            today: parseInt(new Date().getDate()), //本日
			toMonth: parseInt(new Date().getMonth() + 1), //本月
            toYear: parseInt(new Date().getFullYear()), //本年
            day: '',
            list: [],
            addshow: false,
            ftime:''
        }
    },
    created() { 
        this.today = parseInt(new Date().getDate());//本日
        this.day=this.today
        this.toMonth = parseInt(new Date().getMonth() + 1); //本月
        this.toYear = parseInt(new Date().getFullYear()); //本年
        let data={
                 start_time: this.toYear + '-' + this.toMonth + '-' + this.today+" 00:00:00",
                 end_time :this.toYear +'-'+this.toMonth+'-'+this.today+" 23:59:59",                 
                }
        this.getList(data)
             this.cur_year =new Date().getFullYear();
			this.cur_month = new Date().getMonth() + 1;
			this.calculateEmptyGrids(this.cur_year, this.cur_month);
			this.calculateDays(this.cur_year, this.cur_month);
        
              this.getAll()
    },
    methods: {
        toAdd() {       
            this.ftime = this.cur_year + '-' + this.cur_month + '-' + this.today
            if (this.addshow) { this.addshow = false }
            setTimeout(() => {
                this.addshow = true
            }, 100);
        },
        getAll() { 
            let l=this.days.length-1
             let  data={
                 start_time: this.cur_year + '-' + this.cur_month + '-' + 1+" 00:00:00",
                 end_time :this.cur_year +'-'+this.cur_month+'-'+this.days[l].date+" 23:59:59",                 
            }
            console.log(data);
                this.$post({
        url: "getCalendarSign",
             data: data,
             callback: (res) => {
          this.$nextTick(function () {
              this.SignUp = res.data
                 this.onJudgeSign();     
          });
        },
      });
        },
        getList(data) { 
            if (!data) {           
           data={
                 start_time: this.cur_year + '-' + this.cur_month + '-' + this.today+" 00:00:00",
                 end_time :this.cur_year +'-'+this.cur_month+'-'+this.today+" 23:59:59",                 
                }
        }
        this.loading=true
         this.$post({
        url: "getCalendarSign",
             data: data,
             callback: (res) => {
            this.list=[]
          this.$nextTick(function () {
              this.loading = false
              this.list=res.data
            // for(var key in res.data ){
            //     console.log(key)
            //     console.log(res.data[key]);
            // this.list.push( {'time':key,text:res.data[key]})   
            // }
           
          });
        },
      });
    },
        // 获取当月共多少天
        getThisMonthDays(year, month) {
				return new Date(year, month, 0).getDate()
        },
        // 获取当月第一天星期几
		getFirstDayOfWeek(year, month) {
				return new Date(Date.UTC(year, month - 1, 1)).getDay();
        },
        // 计算当月1号前空了几个格子，把它填充在days数组的前面
			calculateEmptyGrids(year, month) {
				//计算每个月时要清零
				this.days = [];
				const firstDayOfWeek = this.getFirstDayOfWeek(year, month);
				if (firstDayOfWeek > 0) {
					for (let i = 0; i < firstDayOfWeek; i++) {
						var obj = {
							date: null,
							isSign: false
						}
						this.days.push(obj);
					}
				}
        },
            // 绘制当月天数占的格子，并把它放到days数组中
			calculateDays(year, month) {

				const thisMonthDays = this.getThisMonthDays(year, month);
				// this.columnsLen=Math.ceil(thisMonthDays/7);
				// console.log(this.columnsLen);
				for (let i = 1; i <= thisMonthDays; i++) {
					var obj = {
						date: i,
						isSign: false
					}
					this.days.push(obj);
				}
				//console.log(this.days);

        },

        setday(e) { 
            this.today = e
               let tdata={
                 start_time: this.cur_year + '-' + this.cur_month + '-' + this.today+" 00:00:00",
                 end_time :this.cur_year +'-'+this.cur_month+'-'+this.today+" 23:59:59",                 
                     }
                this.getList(tdata)
        },
        // 切换控制年月，上一个月，下一个月
			handleCalendar(type) {
				const cur_year = parseInt(this.cur_year);
				const cur_month = parseInt(this.cur_month);
				var newMonth;
				var newYear = cur_year;
				if (type === 0) { //上个月
					newMonth = cur_month - 1;
					if (newMonth < 1) {
						newYear = cur_year - 1;
						newMonth = 12;
					}
				} else {
					newMonth = cur_month + 1;
					if (newMonth > 12) {
						newYear = cur_year + 1;
						newMonth = 1;
					}
				}
				this.calculateEmptyGrids(newYear, newMonth);
				this.calculateDays(newYear, newMonth);
				this.cur_year = newYear;
                this.cur_month = newMonth;
                let tdata={
                 start_time: this.cur_year + '-' + this.cur_month + '-' + this.today+" 00:00:00",
                 end_time :this.cur_year +'-'+this.cur_month+'-'+this.today+" 23:59:59",                 
                     }
                this.getList(tdata)
                this.getAll()
        },

        //匹配判断当月与当月哪些日子添加了备注
			onJudgeSign() {
				var signs = this.SignUp;
				var daysArr = this.days;
				for (var i = 0; i < signs.length; i++) {
					var current = new Date(signs[i].day.replace(/-/g, "/"));
					var year = current.getFullYear();
					var month = current.getMonth() + 1;
					var day = current.getDate();
					day = parseInt(day);
					for (var j = 0; j < daysArr.length; j++) {
						//年月日相同则打卡成功   						
						if (year == this.cur_year && month == this.cur_month && daysArr[j].date == day) { //&& signs[i].isSign == "今日已打卡"
							// console.log(daysArr[j].date, day);
							daysArr[j].isSign = true;
						}
					}
				}
				this.days = daysArr;
			},
    },
    }
</script>
<style scoped lang="scss">
.carBox{
    width: auto;
    margin: 10px auto;
    .top{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .sleft{
            display: flex;
            align-items: center;
            img{
                width: 12px;
                height: 12px;
                margin: 5px;
            }
        }
        .conte{
            font-size: 16px;
font-weight: 500;
color: #323233;
line-height: 24px;
        }
    }
    .week{
        		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin: 20px 0 0;
        .cell{
            width: 50px;
               display: flex;
			justify-content: center;
              width: 50px;
              height: 50px;
			align-items: center;
            color: #939496;
			font-size: 16px;
        }
    }
    .myDateTable{
        display: inline-block;
			text-align: center;
            display: flex;
            flex-wrap: wrap;
            .cell{
            display: flex;
			justify-content: center;
              width: 50px;
              height: 50px;
			align-items: center;
            color: #323233;
			font-size: 16px;
            cursor: pointer;
            .i{
                width: 45px;
                height: 45px;
                  display: flex;
			justify-content: center;
            align-items: center;
            }
            }
            .now{
                background: #FFFFFF;
                 border-radius: 16px;
            }
            .act{
                background: #445AF7;
                border-radius: 16px;
                color: #ffffff;
            }
            .bw{
              position: relative;
            }
            .bw::after{
                position: absolute;
                content: "";
                background: red;
                width: 5px;
                border-radius: 100%;
                height: 5px;
                bottom: 10px;
            }
    }
     .add{
            background: #7188b6;
            width: 120px;
            height: 34px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 10px;
            font-size: 16px;
            margin-top: 20px;
            cursor: pointer;
            margin: 10px auto 0;
        }
    .list{
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 8px;
        height: 200px;
        overflow: auto;
        .items{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-top: 10px;
            position: relative;
            .time{
                font-size: 14px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #9FA4BB;
line-height: 20px;
            }
            .txt{
                width: 227px;
height: 40px;
background: #FFFFFF;
border-radius: 4px;
font-size: 14px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #000000;
line-height: 20px;
display: flex;
align-items: center;
justify-content: flex-start;
padding-left: 22px;
margin-top: 10px;
            }
        }
         .items::before{
    position: absolute;
    left: -12px;    
    content: "";
    width: 6px;
    top: 10px;
height: 6px;
background: #F12A49;
transform: translate(-50% ,-50%);
border-radius: 100%;
  }
       
    }
}

</style>