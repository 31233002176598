import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userWallet: "" || sessionStorage.getItem("userWallet"),
    token: "" || sessionStorage.getItem("token"),
    phone: "" || sessionStorage.getItem("phone"),
    usdt: "" || sessionStorage.getItem("usdt"),
    myID: "" || sessionStorage.getItem("myID"),
    intID: "" || sessionStorage.getItem("intID"),
    myTrx: "" || localStorage.getItem("myTrx"),
    myFst: "" || localStorage.getItem("myFst"),
    myDmcc: "" || localStorage.getItem("myDmcc"),
    mylevel: "" || sessionStorage.getItem("mylevel"),
    language: localStorage.getItem('yy') || "zh-CN",
    theame: localStorage.getItem('theame') || '0',
  },
  mutations: {
    userWallet(state, val) {
      state.userWallet = val;
      sessionStorage.setItem("userWallet", val);
    },
    token(state, val) {
      state.token = val;
      sessionStorage.setItem("token", val);
    },
    language(state, val) {
      state.language = val
      localStorage.setItem('yy', val)
    },
    phone(state, val) {
      state.phone = val;
      sessionStorage.setItem("phone", val);
    },
    usdt(state, val) {
      state.usdt = val;
      sessionStorage.setItem("usdt", val);
    },
    myID(state, val) {
      state.myID = val;
      sessionStorage.setItem("myID", val);
    },
    intID(state, val) {
      state.intID = val;
      sessionStorage.setItem("intID", val);
    },
    myTrx(state, val) {
      state.myTrx = val;
      localStorage.setItem("myTrx", val);
    },
    myFst(state, val) {
      state.myFst = val;
      localStorage.setItem("myFst", val);
    },
    myDmcc(state, val) {
      state.myDmcc = val;
      localStorage.setItem("myDmcc", val);
    },
    mylevel(state, val) {
      state.mylevel = val;
      sessionStorage.setItem("mylevel", val);
    },
    theame(state, val) {
      state.theame = val;
      localStorage.setItem("theame", val);
    },
  },
  actions: {},
  modules: {},
  getters: {
    userWallet: (state) => state.userWallet,
    token: (state) => state.token,
    usdt: (state) => state.usdt,
    phone: (state) => state.phone,
    myID: (state) => state.myID,
    intID: (state) => state.intID,
    myTrx: (state) => state.myTrx,
    myFst: (state) => state.myFst,
    myDmcc: (state) => state.myDmcc,
    language: (state) => state.language,
    theame: (state) => state.theame,
    mylevel: (state) => state.mylevel
  },
});
