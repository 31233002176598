var render = function render(){var _vm=this,_c=_vm._self._c;return _c('van-popup',{attrs:{"round":"","position":_vm.isH5?'bottom':'center',"close-on-click-overlay":false,"closeable":!_vm.loading},on:{"close":_vm.close},model:{value:(_vm.shows),callback:function ($$v) {_vm.shows=$$v},expression:"shows"}},[_c('div',{staticClass:"popup",class:_vm.isH5?'h5':''},[_c('div',{staticClass:"stop"},[_c('div',{staticClass:"title"},[_vm._v("添加备忘")])]),_c('div',{staticClass:"box"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"laber"},[_vm._v("工作日期：")]),_c('div',{staticClass:"right"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data),expression:"data"}],attrs:{"type":"text","readonly":""},domProps:{"value":(_vm.data)},on:{"input":function($event){if($event.target.composing)return;_vm.data=$event.target.value}}})])]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"laber"},[_vm._v("开始时间：")]),_c('div',{staticClass:"right"},[_c('el-time-select',{attrs:{"picker-options":{
    start: '0:00',
    step: '00:15',
    end: '24:00'
  },"placeholder":"选择时间"},model:{value:(_vm.time_start),callback:function ($$v) {_vm.time_start=$$v},expression:"time_start"}})],1)]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"laber"},[_vm._v("结束时间：")]),_c('div',{staticClass:"right"},[_c('el-time-select',{attrs:{"picker-options":{
    start: _vm.time_start,
    step: '00:15',
    end: '24:00'
  },"placeholder":"选择时间"},model:{value:(_vm.time_end),callback:function ($$v) {_vm.time_end=$$v},expression:"time_end"}})],1)]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"laber"},[_vm._v("内容：")]),_c('div',{staticClass:"right"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.detail),expression:"detail"}],staticClass:"txear",attrs:{"name":"","id":"","cols":"30","rows":"10","placeholder":"请填写备忘内容"},domProps:{"value":(_vm.detail)},on:{"input":function($event){if($event.target.composing)return;_vm.detail=$event.target.value}}})])])]),_c('div',{staticClass:"smbottom"},[_c('van-button',{staticClass:"btn qbtn",attrs:{"loading":_vm.loading,"type":"primary"},on:{"click":_vm.close}},[_vm._v(" 取消")]),_c('van-button',{staticClass:"btn",attrs:{"loading":_vm.loading,"type":"primary","loading-text":"Loading..."},on:{"click":_vm.Upadata}},[_vm._v(" 提交")])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }