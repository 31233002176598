import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue"
// import Ranklist from "../views/Ranklist.vue"
// import Record from "../views/record.vue"

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
  path: "/Login",
  name: "Login",
  component: Login,
  },
  // {
  //   path: "/Home",
  //   name: "Home",
  //   component: Home,
  // },
  // {
  //   path: "/Ranklist",
  //   name: "Ranklist",
  //   component: Ranklist
  // },
  // {
  //   path: "/Record",
  //   name: "Record",
  //   component: Record
  // }
];

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    //设置回到顶部
    if (savedPosition) {
      return savedPosition;
    }
    return {
      x: 0,
      y: 0
    };
  },
});

export default router;