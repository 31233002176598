export const m = {
    ysq: 'authorized',
  wsq:'unauthorized',
  jrl: ' Joined the FILPlay',
    
   bqhd:'FILmine introduction',
  hdxq: 'FILmine100% distributed miner marketing plan is based on HECO, huobi ecological public chain, to develop DAPP smart contract. The contract passes the audit, and the chain is operated safely. The mining pool is directly corresponding to nodes for joint mining, and the data is real and veriable, transparent and open. Each miner is given double computing power value, computing power value is encapsulated and computing power is combined with mining for 1080 days. According to the average value of the whole network, monetary output income is obtained. According to the distribution rules, technical operation and maintenance is allocated 20%, hard disk cost is allocated 10%, and FILmine miners are allocated 70%, of which 25% can be extracted in coins and 75% can be linearly released for 180 days. Miners can also share sermons, joint mining to get more money, leverage fission FIL assets, promote more miners long-term belief to hold FIL, break the monopoly of capitalists to harvest, promote the development of IPFS interagency file storage track, let more people access to IPFS brought to human privacy storage, safe storage, low cost storage retrieval and other convenience, In the FILmine marketing plan to achieve all miners, all mines.',
  

  jiaocheng:'Course',

  ljcy: 'Participate in FILmine',
  zcyz: 'The total participants',
  d:'天',
  s:'时',
  f:'分',
  m:'秒',
  zrcy:'Participated in HFIL quantity yesterday',
  jqrcy: 'The number of HFIL participants in the past seven days',
  kc: 'Ore pool',
  ktsl: 'To mention the number',
  jtsc:'Static lock up',
  ljsf: 'Available',
  kysl:'Encapsulation is force',
  mrmtcc: 'Output per T per day',
  scsl:'Linear release for 180 days',
  sfsl:'Release amount',
  minx: 'Detailed',
  tix:'Withdraw',

  gp: 'B',
  dqjzdj: 'Current A Level',
  qyjz: 'Enable the A',
  dqgpdj: 'Current B level',
  qygp: 'Enable the B',
  qyjzhgp: 'Enable A and B',
  jzhgptskt: 'A and B must be opened at the same time',
  buy:'Open',
  yqy:'Is enabled',

  yfmx: 'Calculate  detail',
  slmx: 'Hashrate detail',
  wkmx:'Mining detail',
  ktslbz:'The number of withdrawn coins is insufficient',
  tq: 'Extract the FIL',
  bzs: 'currency',
  zzdz: 'Transfer the address',
  asdds:'Type or long press the paste address,(HECO) channel',
  sls: 'amount ',
  zscdw:'The minimum transfer amount is ',
  tjiao: 'Submit',
  tjiaolog: 'Submiting...',
  
  kgjzsy: 'Miner A payoff',
  kggpsy :'Miner B payoff',
  tdzrcy: 'Yesterday the team invested in HFIL',
  tdjqrcy:'The team invested in HFIL accumulatively',
  ljqy:'FILmine is enabled accumulatively',
  ljfjz:'Enable FILmine values',

  wdtj: 'Share the miners',
    dz: 'Address',
  time:'Time',
  tibsuc: 'Mention money success',
  tiberr:'Mention money failed',
 yddzme:'Maximum daily withdrawals have been reached',

  none: 'FILmine',
  jdll: 'United mining pool',
  jdb: 'United mining pool list',
  fzjd: 'See node number',
    jdfh:'Node bonus can be mentioned FIL',
  gbs: 'Close',
      noader:'The invitation address you bound does not meet the binding requirements!',
  kjz: 'Calculate',
  

  dqjrhb: 'Join partners within 24 hours',
  cyyzq: 'Participants have earned',
  zzc: 'Total assets',
  wdby: 'Single game',
  di: 'Processing',
  jjc:'prize pool',
  kjdjs: 'Lottery countdown',
  dqlxlb: 'Current Leading List',
  zk: 'an',
  sq:'Pack up',
  px: 'Sort',
  dizhi: 'Address',
  yjjl: 'Expected reward',
  loading: 'Loading...',
  zzqb: 'Support the purse',
  qbsqtt: 'Wallet authorizes your account',
  sqinfo: 'To use all the functions of your personal account, use Wallet Authorization',
  sqbtn: 'The wallet authorization',
  sqload:'The authorization...',
  dltt: 'Log in to your account',
  dlinfo: 'To use all the features of your personal account, use Automatic Login',
  dlbtn: 'Automatic login',
  xzlang: 'Select language',
  whqyqdz: 'The inviter\'s address information was not obtained',
    bangsuc: "Binding success",
  banger: 'Binding failure',
  qxsq: 'Please authorize first!',
  bdyqrdz:'Please bind the inviter\'s address!',
  qsydzdl:'Please use the TRON address to log in to the game',

  
   

  fzcg: 'Copy success',
  fzsb: 'Copy the failure',
  buysuc: 'Open the success',
  buyer: 'Open the failure',
  reasuc: 'Refresh the success',
  ftjb: 'After cast level',
  grztdhb: 'Personal straight push partner',
  sjhl: 'It\'s coming down from above',
  xmhl: 'It\'s coming down',
  yjhb: 'A partner over the top',
  zfgsj: 'Pay off a superior',
  
  
  yjszgj: 'The highest level has been reached!',
  yjsftzdz: 'It is already the maximum of the overthrow!',

  bdtt: 'Binding the superior',
  bdinfo: 'Please enter the reference\'s wallet address',
  bdbtn: 'Confirm',
    bdload:'Loading...',


    
  jg:'price',
  jrcc:'Today\'s output',
  zcjd:'Borrow',
  wdsq:'Community',
  zcmx:'Asset',
  slwk:'Mining',
  qwsj:'Whole network data',
  qwsl:'Network power',
  qwdhsl:'exchange power',
  qwzysl:'Pledge amount',
  qwxhsl:'Destroy power',
  djzc:'Frozen assets',
  khzzc:'Transferable assets',
  khzcs:'Number of transfers',
  ci:'N',
  hz:'Transfer',
  wdzc:'My assets',
  cz:'Recharge',
  tx:'Withdraw',
  wddj:'My grade',
  qjd:'Light node',
  ydc:'Reached',
  wdc:'undone',
  sjtj1:'Upgrade conditions: personal computing power to maintain 1000 computing power, pledge 1000 KKC',
  xsqy1:'Enjoy rights: 5% evenly divided (daily coin production)',
  zijd:'Child node',
  sjtj2:'Upgrade conditions: personal computing power to maintain 5000 computing power, direct push to maintain 3 light nodes',
  xsqy2:'Enjoy rights: 5% evenly divided (daily coin production)',
  cjjd:'Super node',
  sjtj3:'Upgrade conditions: personal computing power to maintain up to 30,000 computing power, direct push to maintain 2 child nodes',
  xsqy3:'Enjoy rights: 5% evenly divided (daily coin production)',
  zjd:'Master node',
  sjtj4:'Upgrade conditions: personal computing power reaches 100,000 computing power, direct push to maintain 2 super nodes',
  xsqy4:'Enjoy rights: 5% evenly divided (daily coin production)',
  hzhb:'Partners',
  ye:'Balance',
  qdcz:'Confirm recharge',
  dqzcye:'Total current assets',
  sxf:'Handling fee:',
  qdtx:'Confirm withdrawal',
  hzwhz:'Transferring maintenance',
  hzdjzcbz:'Insufficient transferred frozen assets',
  hzcg:'Successful transfer',
  hzsb:'Transfer failed',
  czcg:'Top up successfully',
  tbwhz:'Withdrawal is under maintenance',
  ktxedbz:'Insufficient withdrawal limit',
  txsqcg:'The withdrawal application is successful!',

  dhdd:'Redeem order',
  zydd:'Pledge order',
  xhdd:'Destroy order',
  ssdd:'Search order',
  wywk:'Mining',
  dhsl:'Exchanges amount',
  zysl:'Pledge amount',
  xhsl:'Quantity destroyed',
  cjjs:'Returns the node',
  sj:'Time',
  wkccbl:'Mining output ratio',
  ri:'day',
  bzz:'Remark',
  dqzdjy:'Automatic release at maturity',
  kssjy:'Can be released at any time',
  zyzq:'Pledge cycle',
  tian:'day',
  ss:'Any',
  jysj:'Release time',
  xhssbj:'Destroy real-time currency prices',
  hdsl:'Get computing power',
  ljsy:'Cumulative income',
  jrsy:'Today\'s earnings',
  sqjy:'Apply for release',
  wkz:'Mining',
  yjs:'Settled',
  lpdon:'Token DON',
  dhbl:'Exchange ratio',
  qddh:'Confirm exchange',
  xh:'Destroy',
  qxzxhsl:'Please select the quantity to be destroyed',
  ujzd:'U value KKC',
  zfdyd:'Pay the corresponding burnt coins',
  xhdsl:'Number of KKC burned',
  bcj:'Times out',
  yysl:'Original computing power',
  sjhqsl:'Get the actual hashrate value',
  qdxh:'Confirm to destroy',
  zy:'Pledge',
  kzy:'Pledgeable',
  sddrq:'Lock expiry date',
  zyhd:'Pledged',
  sl:'Computing power',
  sy:'income',
  dqkcsxf:'Service fee deduction due',
  qdzy:'Confirm pledge',
  jyfs:'Release method',
  slbl:'Computing power ratio',
  jszy:'Settlement pledge',
  sh:'redemption',
  lqsxf:'Accumulated rewards',
  tqsh:'Early redemption',
  qsrdhsl:'Please enter the exchange amount',
  dhcg:'Successful redemption',
  dhsb:'Redemption failed',
  ndzcbz:'Your KKC assets are insufficient to be destroyed',
  xhcg:'Destroyed successfully',
  xhsb:'Destruction failed',
  qsrzyje:'Please enter the pledge amount',
  zycg:'Pledge success',
  zysb:'Pledge failed',
  shz:'Redemption...',
  shcg:'Redemption successful',
  shsb:'Redemption failed',
  qljqb:'Please connect to the wallet',

  jk:'Loan',
  hk:'Repayment',
  jd:'Borrow',
  jdz:'Borrowing...',
  qdjk:'Determine the loan',

  jdjl:'Loan record',
  nky:'Your available',
  jz:'value',
  kyzcdbfb:'30% of the value of KKC assets can be borrowed',
  xznyhkdd:'Choose the order you want to repay',
  xz:'choose',
  hkdqrq:'Repayment due time',
  hkje:'Repayment Amount',
  fh:'Return',
  qdhk:'Confirm repayment',
  ts:'hint',
  jdhdykkc:'After borrowing, the corresponding kkc enters the frozen assets, and the freezing can only be released after the principal and interest are paid at maturity.',
  hktx:'Repayment reminder',
  qdykhdd:'Are you sure to repay the current order?',
  hhz:'In repayment...',
  nmykhkdd:'You have no repayment order',
  qsrjksl:'Please enter the loan amount',
  jkcg:'Successful borrowing',
  jksb:'Loan failed',
  xzhkdd:'Please select repayment order',
  ndzcbzhk:'Your USDT assets are insufficient to complete the repayment',
  hkcg:'Successful repayment',
  hksb:'Repayment failed',

  yqlink:'Invite link',
  yqinfo: 'Share it with your partner via the invite link',
  yqr: 'Referees',
  wdzt:'My direct push',

  grdhsl:'Personal exchange computing power',
  sqdhsl:'Community exchange computing power',
  grzysl:'Number of personal pledges',
  sqzysl:'Number of community pledges',
  grxhsl:'Personal destruction of computing power',
  sqxhsl:'Community destroys computing power',
  yh:'user',
  jrsj:'Join time',
  zwsj:'No data temporarily',

  jdsj:'Loan time',
  bz:'Currency',
  dqrq:'Expire date',
  jkje:'Loan amount',
  hksl:'Repayment amount',
  zt:'state',
  yhk:'Repaid',
  shqr:'Redemption confirmation',
  qdsh:'Are you sure you want to redeem the pledge with the order number',
  qdsh2:'?',

  qb:'All',

  ucz:'USDT Recharge',
  kcz:'KKC Recharge',
  dcz:'DON Recharge',
  utx:'USDT Withdraw',
  ktx:'KKC Withdraw',
  dtx:'DON Withdraw',
  dhkc:'Exchange deduction',
  zykc:'Pledge deduction',
  xhkc:'Deduction destruction',
  dhwksy:'Exchange mining income',
  zywksy:'Pledge mining income',
  xhwksy:'Destroy mining revenue',
  fxsy:'Share revenue',
  tdsy:'Team benefits',
  jdsy:'Node revenue',
  jddj:'Loan freeze',
  jdhk:'Loan repayment',
  jdjd:'Loan unfreeze',
  zycx:'Pledge cancellation',

  gglb:'Announcement list',
  wdtd:'My team',
  syindex:'Index',
  sheny:'Remaining',
  dhwhz:'Redemption under maintenance...',
  nind:'Your',
  sxfbz:'Insufficient TRX has paid the handling fee',
  xtgg:'Announcement',
  txzxed:'Minimum withdrawal amount'
    
}