<template>
  <van-popup
    v-model="shows"
    round
    @close="close"
       :position="isH5?'bottom':'center'"
    :close-on-click-overlay="false"
    :closeable="!loading"
  >
    <div class="popup" :class="isH5?'h5':''">
      <div class="stop">
      <div class="title">{{data.title}}</div>
      
      </div>
      <div class="box">
        <div class="item">
          <div class="laber">名称：</div>
          <div class="right">
            <input type="text" placeholder="名称" v-model="data.title" readonly>
          </div>
        </div>

              <div class="item">
          <div class="laber">类型：</div>
          <div class="right">
            <div class="sinput">{{ data.type==1?'项目': data.type==2?data.cate: '公函' }}</div>
            <!-- <input type="text" placeholder="请填写类型" v-model="data.cate" > -->
            
          </div>
        </div>

              <div class="item" v-if="data.type==2">
          <div class="laber">标题：</div>
          <div class="right">
            <input type="text" placeholder="标题" v-model="data.real_title" readonly>
          </div>
        </div>
        <div class="item" v-if="data.type==1">
          <div class="laber">实际工作时间：</div>
          <div class="right">
            <input type="text" placeholder="实际工作时间" v-model="data.job_time">
          </div>
        </div>
        
              <div class="item">
          <div class="laber">工作日期：</div>
          <div class="sright">

<div class="datetime">
  <div class="tsxt">{{ data.start_time }}</div> 
</div>
<span class="txt">至</span>
<div class="datetime">
  <div class="tsxt">{{ data.end_time }}</div>
</div>

          </div>
        </div>


             <div class="item" v-if="data.type_name">
          <div class="laber">案件类型：</div>
          <div class="right">
            <input type="text" placeholder="案件类型" v-model="data.type_name" readonly>
          </div>
        </div>

             <div class="item" v-if="data.weituoren">
          <div class="laber">委托人：</div>
          <div class="right">
            <input type="text" placeholder="委托人" v-model="data.weituoren" readonly>
          </div>
        </div>
           <div class="item" v-if="data.duifang">
          <div class="laber">对方当事人：</div>
          <div class="right">
            <input type="text" placeholder="对方当事人" v-model="data.duifang" readonly>
          </div>
        </div>
           <div class="item" v-if="data.danwei">
          <div class="laber">受理单位：</div>
          <div class="right">
            <input type="text" placeholder="受理单位" v-model="data.danwei" readonly>
          </div>
        </div>
           <div class="item" v-if="data.chengxu">
          <div class="laber">审理程序：</div>
          <div class="right">
            <input type="text" placeholder="审理程序" v-model="data.chengxu" readonly>
          </div>
        </div>
           <div class="item" v-if="data.anyou">
          <div class="laber">案由：</div>
          <div class="right">
            <input type="text" placeholder="案由" v-model="data.anyou" readonly>
          </div>
        </div>

           <div class="item" v-if="data.anhao">
          <div class="laber">案号：</div>
          <div class="right">
            <input type="text" placeholder="案号" v-model="data.anhao" readonly>
          </div>
        </div>

           <div class="item" v-if="data.detail">
          <div class="laber">案件简介：</div>
          <div class="right">
            <input type="text" placeholder="案件简介" v-model="data.detail" readonly>
          </div>
        </div>


              <div class="item">
          <div class="laber">内容：</div>
          <div class="right">
            
             <textarea v-model="data.content" v-if="data.type==3" id="" readonly cols="30" rows="10"  placeholder="请填写描述" class="txear"></textarea>
            <textarea v-model="detail" id="" cols="30" rows="10" v-else  placeholder="请填写描述" class="txear"></textarea>
          </div>
        </div>
      </div>
        <div class="smbottom ctne" v-if="data.type!=3">
         <van-button
         @click="download"
          :loading="downloadLoading"
          type="primary"
          class="btn"
          loading-text="Loading..."
        >
          下载记录</van-button
        >
      </div>
      <div class="smbottom ctne" v-if="data.type==3"> 
           <van-button
          :loading="loading"
          type="info"
          class="btn"
          :style="'background:'+data.label_color"
           @click="close"
          loading-text="Loading..."
        >
          {{ data.label_title }}</van-button
        >
      </div>

      <div class="smbottom" v-if="data.type!=3">
         <van-button
          :loading="loading"
          type="primary"
          @click="close"
          class="btn qbtn"
          loading-text="Loading..."
        >
          取消</van-button
        >
        <van-button
          :loading="loading"
          type="primary"
          @click="Upadata"
          class="btn"
          loading-text="Loading..."
        >
          提交</van-button
        >
      </div>
    </div>
  </van-popup>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      isH5:false,
      loading: false,
      myFht: 0,
      myTrx: 0,
      shows: false,
      free: 0,
      value: 75,
      active: 1,
      sqnum: 0,
      reshash:'',
      resID: {
        hase:''
      },
      task: "",
      hase: '',
      issq: false,
      currentDate:new Date(),
      formTime: '',
      toTime:'',
      tshow: false,
      tshows: false,
      detail: '',
      downloadLoading: false,
      datalist:[]
      
    };
  },
  watch: {
    show(val) {
      this.shows = val;
      if (val) {
        this.detail = ''
          this.isH5 = this.$store.getters.phone;
      }
    },
  },
  methods: {
    download() { 
   this.$post({
          url: "projectHuiBaoList",
          data: {project_id:this.data.id},
          callback: (res) => {
            this.$nextTick(function () {
              if (res.code == 200) {
                this.datalist=res.data
                this.Export()
              } else {
                this.$toast(res.msg);
              }
            });
          },
        });   
    },
   async  Export(){
      this.downloadLoading = true;    
      import("@/vendor/Export2Excel").then((excel) => {
        //活动编码,活动名称,影片名称,合作方,收款公司,订单总数,创建时间,状态
        const tHeader = [
          "ID",
          "律师",
          "汇报内容",
          "日期",
          "实际时间",
          "更新时间"
        ];
        const filterVal = [
          "id",
          "lvshi_name",
          "content",
          "day",
          "job_time",
          "update_time"
        ];
        const data = this.formatJson(filterVal);
        if (data) {
          excel.export_json_to_excel({
            header: tHeader,
            data:data,
            filename:this.data.title+"工作记录",
          });
        }
          this.downloadLoading = false;
        
        });
      
    },
             formatJson(filterVal) {
      return this.datalist.map((v) =>
        filterVal.map((j) => { {
            return v[j];
          }
        })
      );      
    },
    confirm(e) {
    
        var dd = new Date(e);
              var y = dd.getFullYear();
      var m = dd.getMonth() + 1;//获取当前月份的日期 
      var d = dd.getDate();
             if (d < 10) {
                d = '0' + d;
      }
      console.log(y, m);
      this.data.start_time = y + '-' + m + '-' + d
      this.tshow=false
     },
    confirms(e) {
         var dd = new Date(e);
              var y = dd.getFullYear();
      var m = dd.getMonth() + 1;//获取当前月份的日期 
      var d = dd.getDate();
             if (d < 10) {
                d = '0' + d;
      }
      console.log(y, m);
      this.data.end_time = y + '-' + m + '-' + d
      this.tshows=false
     },
    close() {
      this.$emit("tshow", false);
    },
    toclose() {
      this.active=1
      this.$emit("tshow", false);
      this.$emit("updata", true);
    },
    
    Upadata() {
       if (!this.detail) { return }
      this.loading=true
      if (this.data.type == 1) {
        this.$post({
          url: "/projectUpdate",
          data: {
            project_id: this.data.id,
            content: this.detail,
          },
          callback: (res) => {
            this.$nextTick(function () {
              this.loading = false;
              if (res.code == 200) {
                 this.detail=''
                this.$emit("tshow", false);
                this.$emit("updata", true);
              } else {
                this.$notify(res.msg);
              }
            });
          },
        });
      }
         if (this.data.type == 2) {
        this.$post({
          url: "/documentUpdate",
          data: {
            document_id: this.data.id,
            content: this.detail,
          },
          callback: (res) => {
            this.$nextTick(function () {
              this.loading = false;
              if (res.code == 200) {
                 this.detail=''
                this.$emit("tshow", false);
                this.$emit("updata", true);
              } else {
                this.$notify(res.msg);
              }
            });
          },
        });
      }

        if (this.data.type == 3) {
        this.$post({
          url: "/documentUpdate",
          data: {
            document_id: this.data.id,
            content: this.detail,
          },
          callback: (res) => {
            this.$nextTick(function () {
              this.loading = false;
              if (res.code == 200) {
                 this.detail=''
                this.$emit("tshow", false);
                this.$emit("updata", true);
              } else {
                this.$notify(res.msg);
              }
            });
          },
        });
      }
    },    
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../src/assets/scss/_handle.scss";
$t-mf: "PingFang";
$t-f: "DINAlternate-Bold";
.popup {
  width: 686px;
min-height: 445px;
background: #FFFFFF;
border-radius: 4px;

  .stop {
    height: 69px;
   border-bottom: 1px solid #DCDEE0;
     .title {
    text-align: left;
    margin-bottom: 32px;
    font-size: 19px;
font-weight: 500;
color: #323233;
line-height: 69px;
margin-left: 32px;
  }
  }
 
  .box{
    padding: 29px 0 19px;
    .item{
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 24px ;
      .laber{
font-size: 19px;
font-weight: 400;
color: #323233;
line-height: 27px;
text-align: left;
min-width: 150px;
      }
      .right{
        width: 480px;
        background: #FFFFFF;

        .sinput{
            padding: 0 20px;
          width: 100%;
          height: 50px;
          font-size: 19px;
          color: #030304;
          border: 1px solid #DCDEE0;
          border-radius: 3px;
          text-align: left;
          line-height: 50px;
        }
        input{
          padding: 0 20px;
          width: 100%;
          height: 50px;
          font-size: 19px;
          color: #030304;
          border: 1px solid #DCDEE0;
          border-radius: 3px;
        }
        .txear{
          width: 100%;
          height: 100%;
            min-width: 480px;
          max-width: 480px;
          min-height: 200px;
         border: 1px solid #DCDEE0;
           font-size: 19px;
             padding: 20px;
             color: #030304;
             border-radius: 3px;
        }
      }
      .sright{
        display: flex;
        align-items: center;
        justify-content: space-between;
         width: 480px;
         height: 50px;
         .datetime{
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 200px;
          overflow: hidden;
position: relative;
border-radius: 3px;
border: 1px solid #DCDEE0 ;
height: 43px;
padding: 0 16px;
          .tsxt{
           font-size: 15px;
font-weight: 400;
color: #323233;
line-height: 27px;
white-space: nowrap;
          }
         }
         .datetime:hover{
          border: 1px solid #2163F8;
          box-shadow: 0px 0px 5px 0px rgba(10,42,97,0.2);
          cursor: pointer;
         }
         .popp{
          position: absolute;
          top: 0;
          width: 100%;
          background: #d7ddeb;
         }
         .txt{
          font-size: 19px;
font-weight: 400;
color: #323233;
line-height: 27px;
         }
      }
    }
  }

   .smbottom {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 22px;
      .btn {
    font-size: 19px;
    font-family: $t-mf;
    font-weight: bold;
    color: #ffffff;
    line-height: 46px;
    margin: 10px;
 min-width: 99px;
height: 43px;
background: #2163F8;
border-radius: 3px;
border: none;
  }
      .qbtn {
     background: #FFFFFF;
border-radius: 3px;
border: 1px solid #DCDEE0;
color: #030304;
    }
  }
  .ctne{
      justify-content: center;
      .btn{
        width: 200px;
        cursor: pointer;
      }
  }
}

 .h5{
    width: 100%;
    .stop{
      height: 88px;
      .title{
        font-size: 28px;
      }
    }
    .box{
      .item{
        .laber{
          font-size: 22px;
          white-space: nowrap;
          min-width: 170px;
        }
        .right{
          width: 100%;
          .el-select{
             height: 68px;
            line-height: 68px;
            }
                ::v-deep .el-input__inner{
                height: 68px;
                line-height: 68px;
              }
            
         .sinput, input{
            height: 68px;
            line-height: 68px;
          }
          .sbtn{
            height: 88px;
            width: 160px;
            border-radius: 8px;
          }
          .txear{
            max-width: 100%;
          }
        }
        .sright{
             width: 100%;
          .datetime{
            height: 68px;
            width: 100%;
          }
          .txt{
            margin: 0 10px;
          }
        }
      }
    }
      ::v-deep .el-date-editor.el-input{
                width: 100% !important;
              }
              .smbottom{
                .btn{
                  height: 68px;
                  width: 160px;
                  font-size: 28px;
                }
              }

  }
</style>
