export const m = {
  ysq: '已授权',
  wsq:'未授权',
  jrl: ' 加入了FILmine',



  bqhd:'HUNU介绍',
  hdxq: 'HUNU通证是由Defi发烧友自发共建的翻身通证，强调多维度分散持有和一系列革命性突破。HUNU希望更多平民玩家参与树立新标杆。',


  jiaocheng:'教程',

  d:'天',
  s:'时',
  f:'分',
  m:'秒',
  ljcy: '参与FILmine',
  zcyz: '总参加者',

  zrcy:'昨日团队新增FIL矿工',
  jqrcy: '近七日团队新增FIL矿工',
  kc: '矿池',
  ktsl: '可提币总量',
  jtsc:'静态锁仓',
  ljsf: '可用',
  kysl:'封装算力',
  mrmtcc: '每日每T产出',
  scsl:'180天线性释放',
  sfsl:'释放可提币量',
  minx: '明细',
  tix:'提币',

  gp: 'B',
  dqjzdj: '当前A等级',
  qyjz: '启用A',
  dqgpdj: '当前B等级',
  qygp: '启用B',
  qyjzhgp: '启用A和B',
  jzhgptskt: 'A和B须同时开通',
  buy: '启用',
  yqy: '已启用',
  
  yfmx: '算力值明细',
  slmx: '算力明细',
  wkmx: '产币明细',
  
  ktslbz:'可提币数量不足',
  tq: '提取FIL',
  bzs: '币种',
  zzdz: '收币地址',
  asdds:'输入或长按粘贴地址,请选择（HECO）通道',
  sls: '数量',
  zscdw:'最小转账数量',
  tjiao: '提交',
  tjiaolog: '提交中...',
  
  kgjzsy: '矿工A收益',
  kggpsy :'矿工B收益',

  tdzrcy: '昨日团队新增FILmine',
  tdjqrcy:'团队累计FILmine',
  ljqy:'累计启用FILmine',
  ljfjz:'启用FILmine价值',
  wdtj: '分享矿工',
  yddzme:'已达到每日最大提现次数',
  
  dz: '地址',
  time: '时间',
  tibsuc: '提币成功',
  tiberr:'提币失败',

  none:'暂无',
  jdll: '联合矿池',
  jdb: '联合矿池列表',
  fzjd: '查看矿池',
  jdfh:'节点分红可提FIL',
  gbs: '关闭',
  
  noader: '您绑定的邀请地址不符合绑定要求！',
  kjz:'算力值',



  dqjrhb: '24小时内加入的伙伴',
  cyyzq: '参加者已赚取',
  zzc: '总资产',
  wdby: '尾单博弈',
  di: '进行中',
  jjc:'奖金池',
  kjdjs: '开奖倒计时',
  dqlxlb: '当前领先列表',
  zk: '展开',
  sq:'收起',
  px: '排序',
  dizhi: '地址',
  yjjl: '预计奖励',
  loading: '加载中...',
  zzqb: '支持钱包',
  qbsqtt: '钱包授权您的账户',
  sqinfo: '要使用您个人账户的功能，请使用TRX钱包授权',
  sqbtn: '钱包授权',
  sqload:'授权中...',
  dltt: '登录到您的账户',
  dlinfo: '要使用您个人账户的所有功能，请使用自动登录',
  dlbtn: '自动登录',
  xzlang: '选择语言',
  whqyqdz:'未获取到邀请人地址信息',
  bangsuc: "绑定成功",
  banger: '绑定失败',
  qxsq: '请您先授权！',
  bdyqrdz: '请您绑定邀请人地址！',
  qsydzdl:'请使用波场地址登录游戏',
  

 



  fzcg: '复制成功',
  fzsb: '复制失败',
  buysuc: '开启成功',
  buyer: '开启失败',
  reasuc: '刷新成功',
  ftjb: '复投级别',
  grztdhb: '个人直推的伙伴',
  sjhl: '上面来的滑落',
  xmhl: '下面来的滑落',
  yjhb: '越级的伙伴',
  zfgsj: '支付给上级',

  yjszgj: '已经到达最高级！',
  yjsftzdz: '已经是复投最大值了！',

  bdtt: '推荐人信息',
  bdinfo: '请填写推荐人地址（必填）',
  bdbtn: '确认',
  bdload:'绑定中...',
  
  
 
  jg:'价格',
  jrcc:'今日产出',
  zcjd:'资产借贷',
  wdsq:'我的社区',
  zcmx:'资产明细',
  slwk:'算力挖矿',
  qwsj:'全网数据',
  qwsl:'全网算力',
  qwdhsl:'全网兑换算力',
  qwzysl:'全网质押数量',
  qwxhsl:'全网销毁算力',
  djzc:'冻结资产',
  khzzc:'可划转资产',
  khzcs:'可划转次数',
  ci:' 次',
  hz:'划转',
  wdzc:'我的资产',
  cz:'充值',
  tx:'提现',
  wddj:'我的等级',
  qjd:'轻节点',
  ydc:'已达成',
  wdc:'未达成',
  sjtj1:'升级条件：个人算力保持达到1000算力 质押1000 KKC',
  xsqy1:'享受权益：5%均分（每日产币量）',
  zijd:'子节点',
  sjtj2:'升级条件：个人算力保持达到5000算力、直推保持3个轻节点',
  xsqy2:'享受权益：5%均分（每日产币量）',
  cjjd:'超级节点',
  sjtj3:'升级条件：个人算力保持达到30000算力、直推保持2个子节点',
  xsqy3:'享受权益：5%均分（每日产币量）',
  zjd:'主节点',
  sjtj4:'升级条件：个人算力达到100000算力、直推保持2个超级节点',
  xsqy4:'享受权益：5%均分（每日产币量）',
  hzhb:'合作伙伴',
  ye:'余额',
  qdcz:'确认充值',
  dqzcye:'当前资产总额',
  sxf:'手续费：',
  qdtx:'确认提现',
  hzwhz:'划转维护中',
  hzdjzcbz:'划转冻结资产不足',
  hzcg:'划转成功',
  hzsb:'划转失败',
  czcg:'充值成功',
  tbwhz:'提币维护中',
  ktxedbz:'可提现额度不足',
  txsqcg:'提现申请成功！',

  dhdd:'兑换订单',
  zydd:'质押订单',
  xhdd:'销毁订单',
  ssdd:'搜索订单',
  wywk:'我要挖矿',
  dhsl:'兑换数量',
  zysl:'质押数量',
  xhsl:'销毁数量',
  cjjs:'收益节点',
  sj:'时间',
  wkccbl:'挖矿产出比例',
  ri:'日',
  bzz:'备注',
  dqzdjy:'到期自动解押',
  kssjy:'可随时解押',
  zyzq:'质押周期',
  tian:'天',
  ss:'随时',
  jysj:'解押时间',
  xhssbj:'销毁实时币价',
  hdsl:'获得算力',
  ljsy:'累计收益',
  jrsy:'今日收益',
  sqjy:'申请解押',
  wkz:'挖矿中',
  yjs:'已结算',
  lpdon:'令牌DON',
  dhbl:'兑换比率',
  qddh:'确定兑换',
  xh:'销毁',
  qxzxhsl:'请选择销毁数量',
  ujzd:'U价值KKC',
  zfdyd:'支付对应的销毁币',
  xhdsl:'销毁的KKC数量',
  bcj:'倍出局',
  yysl:'原有算力',
  sjhqsl:'实际获取算力值',
  qdxh:'确定销毁',
  zy:'质押',
  kzy:'可质押',
  sddrq:'锁定到期日',
  zyhd:'质押获得',
  sl:'算力',
  sy:'收益',
  dqkcsxf:'到期扣除手续费',
  qdzy:'确定质押',
  jyfs:'解押方式',
  slbl:'算力比例',
  jszy:'结算质押',
  sh:'赎回',
  lqsxf:'累计领取奖励',
  tqsh:'提前赎回',
  qsrdhsl:'请输入兑换数量',
  dhcg:'兑换成功',
  dhsb:'兑换失败',
  ndzcbz:'您的KKC资产不足无法销毁',
  xhcg:'销毁成功',
  xhsb:'销毁失败',
  qsrzyje:'请输入质押金额',
  zycg:'质押成功',
  zysb:'质押失败',
  shz:'赎回中...',
  shcg:'赎回成功',
  shsb:'赎回失败',
  qljqb:'请连接钱包',

  jk:'借款',
  hk:'还款',
  jd:'借贷',
  jdz:'借款中...',
  qdjk:'确定借款',

  jdjl:'借贷记录',
  nky:'您的可用',
  jz:'价值',
  kyzcdbfb:'可用KKC资产价值的30%进行借贷',
  xznyhkdd:'选择您要还款的订单',
  xz:'选择',
  hkdqrq:'还款到期时间',
  hkje:'还款金额',
  fh:'返还',
  qdhk:'确定还款',
  ts:'提示',
  jdhdykkc:'借贷后对应的kkc进入冻结资产，到期支付本息后方可解冻。',
  hktx:'还款提醒',
  qdykhdd:'确定还款当前订单吗？',
  hhz:'还款中...',
  nmykhkdd:'您没有可还款订单',
  qsrjksl:'请输入借款数量',
  jkcg:'借款成功',
  jksb:'借款失败',
  xzhkdd:'请选择还款订单',
  ndzcbzhk:'您的USDT资产不足无法完成还款',
  hkcg:'还款成功',
  hksb:'还款失败',

  yqlink:'邀请链接',
  yqinfo: '通过邀请链接分享给你的伙伴',
  yqr: '推荐人',
  wdzt:'我的直推',

  grdhsl:'个人兑换算力',
  sqdhsl:'社区兑换算力',
  grzysl:'个人质押数量',
  sqzysl:'社区质押数量',
  grxhsl:'个人销毁算力',
  sqxhsl:'社区销毁算力',
  yh:'用户',
  jrsj:'加入时间',
  zwsj:'暂时没有数据',

  jdsj:'借贷时间',
  bz:'币种',
  dqrq:'到期时间',
  jkje:'借款金额',
  hksl:'还款数量',
  zt:'状态',
  yhk:'已还款',
  shqr:'赎回确认',
  qdsh:'您确定要赎回订单编号为',
  qdsh2:'的质押吗？',

  qb:'全部',

  ucz:'USDT充值',
  kcz:'KKC充值',
  dcz:'DON充值',
  utx:'USDT提现',
  ktx:'KKC提现',
  dtx:'DON提现',
  dhkc:'兑换扣除',
  zykc:'质押扣除',
  xhkc:'销毁扣除',
  dhwksy:'兑换挖矿收益',
  zywksy:'质押挖矿收益',
  xhwksy:'销毁挖矿收益',
  fxsy:'分享收益',
  tdsy:'团队收益',
  jdsy:'节点收益',
  jddj:'借贷冻结',
  jdhk:'借贷还款',
  jdjd:'借贷解冻',
  zycx:'质押撤销',

  gglb:'公告列表',
  wdtd:'我的团队',
  syindex:'首页',

  sheny:"剩余",
  dhwhz:'兑换维护中...',
  nind:'您的',
  sxfbz:'TRX不足已支付手续费',
  xtgg:'系统公告',
  txzxed:'提现最小额度'









  
  
  
}